import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
    LoginSocialFacebook,
    //LoginSocialInstagram,
    LoginSocialGoogle,
    IResolveParams
} from "reactjs-social-login";

import ForgotPasswordModal from "./Modal/ForgotPasswordModal";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import Validations from "../../components/Utility/Validations";

class signin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login_form: {
                email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter your email",
                    validations: { required: true, email: true },
                },
                password: {
                    type: "password",
                    label: "Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter your password",
                    validations: { required: true },
                },
                terms: {
                    valid: true,
                    onBlur_out: false,
                    value: "terms and conditions",
                    isChecked: false,
                    validations: { required: true, terms: true },
                },
                remember: {
                    valid: true,
                    error_msg: "",
                    type: "checkbox",
                    isChecked: false,
                    value: "remember",
                    label: "Keep me signed in",
                    validations: { required: false },
                },
            },
            forgot_password_modal: false,
            eye : 'fa fa-eye-slash'
        };
    }

    inputChangeHandler(event, identifier) {
        const update_login_form = { ...this.state.login_form };
        const form_element = { ...update_login_form[identifier] };
        form_element.value = event.target.value;
        if(identifier === "terms"){
            if(event.target.checked){
                form_element.isChecked = true;
            }else{
                form_element.isChecked = false;
            }
            
        }
        // Check Validation
        let validate_element = Validations(event, form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_login_form[identifier] = form_element;

        this.setState({ login_form: update_login_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_login_form = this.state.login_form;
        for (let key in update_login_form) {
            let form_element = update_login_form[key];
            
            let validate_element = Validations(event, form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_login_form[key] = form_element;
        }
        this.setState({ login_form: update_login_form });

        if (is_form_valid) {
            const form_data = new FormData();
            form_data.append("user_type", "customer");
            for (let key in this.state.login_form) {
                form_data.append(key, this.state.login_form[key].value);
            }

            this.props.onSignIn(form_data).then((response) => {
                if (response.success === true) {
                    this.props.onGetAuthUser().then((response) => {
                        if (response.success === true) {
                            toast.success("Logged in successfully", { position: toast.POSITION.TOP_RIGHT });
                            setTimeout(() => {
                                this.setState({ redirect_url: "/", isAuthenticated: true });
                            }, 1000);
                        } else {
                            toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
                        }
                    });
                } else {
                    toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
                }
            });
        }
    };

    handleSocialLogin = (provider, user) => {
        const form_data = new FormData();
        form_data.append("provider", provider);
        form_data.append("user", JSON.stringify(user));

        this.props.onSocialSignIn(form_data).then((response) => {
            if (response.success === true) {
                this.props.onGetAuthUser().then((response) => {
                    if (response.success === true) {
                        toast.success(response.message, { position: toast.POSITION.TOP_LEFT });
                        setTimeout(() => {
                            this.setState({ redirect_url: "/", isAuthenticated: true });
                        }, 500);
                    } else {
                        toast.error(response.message, { position: toast.POSITION.TOP_LEFT });
                    }
                });
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_LEFT });
            }
        });
    };

    ForgotPassword = (form_data) => {
        this.props.onForgotPassword(form_data).then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        forgot_password_modal: false,
                    },
                    () => {
                        toast.success(response.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                );
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        });
    };
    terms(event) {
        console.log(event, "event");
        if (event.target.checked) {
            this.setState({
                terms: true,
            });
        } else {
            this.setState({
                terms: false,
            });
        }
    }
    handleClickEye = () => {
        if(this.state.login_form.password.type === 'password'){
            this.setState(prevState => ({
                login_form : {
                    ...prevState.login_form,
                    password : {
                        ...prevState.login_form.password,
                        type: 'text',
                    }
                },
                eye : 'fa fa-eye'
    
            }))
        }else{
            this.setState(prevState => ({
                login_form : {
                    ...prevState.login_form,
                    password : {
                        ...prevState.login_form.password,
                        type: 'password',
                    }
                },
                eye : 'fa fa-eye-slash'
    
            }))
        }

    }

    render() {
        const { login_form, terms, eye } = this.state;
        return (
            <React.Fragment>
                <ToastContainer />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                {this.state.forgot_password_modal && (
                    <ForgotPasswordModal
                        forgot_password_modal={this.state.forgot_password_modal}
                        ForgotPassword={(form_data) => this.ForgotPassword(form_data)}
                        forgot_password_modal_action={(modal_action) => {
                            this.setState({
                                forgot_password_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div className="">
                    <LoginHeader />
                    <div className="login-sec">
                        <div className="conatiner-fluid">
                            <div className="row">
                                <div className="login-form">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="field-sec">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <h3 className="sing_in">Welcome to wholesale purchasing …</h3>
                                                        <div className="form-group">
                                                            <input
                                                                type={login_form.email.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={login_form.email.placeholder}
                                                                value={login_form.email.value ? login_form.email.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                onBlur={(event) => {
                                                                    login_form.email.onBlur_out = true;
                                                                    this.setState({
                                                                        login_form: login_form,
                                                                    });
                                                                }}
                                                            />
                                                            {!login_form.email.valid && login_form.email.onBlur_out && <div className="error field-error">{login_form.email.error_msg}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type={login_form.password.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={login_form.password.placeholder}
                                                                value={login_form.password.value ? login_form.password.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "password")}
                                                                onBlur={(event) => {
                                                                    login_form.password.onBlur_out = true;
                                                                    this.setState({
                                                                        login_form: login_form,
                                                                    });
                                                                }}
                                                            />
                                                            <i className={eye}
                                                               id="togglePassword"
                                                               onClick={()=> this.handleClickEye()}>
                                                               </i>
                                                            {!login_form.password.valid && login_form.password.onBlur_out && <div className="error field-error">{login_form.password.error_msg}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="invalid-feedback"></div>
                                                        </div>
                                                        <div className="mb-1">
                                                            <div className="form-check">
                                                                <input
                                                                 name="terms"
                                                                 type="checkbox"
                                                                 id="validationFormik03"
                                                                 className="form-check-input"
                                                                 value={login_form.terms.value ? login_form.terms.value : ""}
                                                                 onChange={(event) => this.inputChangeHandler(event, "terms")}
                                                                 onBlur={(event) => {
                                                                    login_form.terms.onBlur_out = true;
                                                                    this.setState({
                                                                        login_form: login_form,
                                                                    });
                                                                }}
                                                                  />
                                                                <label title="" htmlFor="validationFormik03" className={login_form.terms.valid ? "form-check-label":"error field-error" }>
                                                                    I agree to the <Link to="/terms-condition">Terms and Conditions</Link>
                                                                </label>
                                                            </div>
                                                            <div className="invalid-feedback"></div>
                                                        </div>
                                                        <span
                                                            className="curser_pointer forgot"
                                                            onClick={(event) => {
                                                                this.setState({
                                                                    forgot_password_modal: true,
                                                                });
                                                            }}
                                                        >
                                                            Forgot Password?
                                                        </span>
                                                        <button type="submit" className="btn btn-primary sign-in">
                                                            Sign In
                                                        </button>
                                                        <p className="or">OR</p>
                                                    </form>
                                                    <div className="social-icon">
                                                        <ul>
                                                            <li>
                                                                <LoginSocialFacebook
                                                                    appId={process.env.REACT_APP_FACEBOOK}
                                                                    fieldsProfile={"first_name,last_name,name,email"}
                                                                    onResolve={({ provider, data }: IResolveParams) => this.handleSocialLogin(provider, data)}
                                                                    onReject={(error) => toast.error(error.message, { position: toast.POSITION.TOP_LEFT })}
                                                                >
                                                                    <img src="/assets/images/Facebook.svg" alt="facebook" />
                                                                </LoginSocialFacebook>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <img src="/assets/images/Twitter.svg" alt="twitter" />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <img src="/assets/images/Instagram.svg" alt="insta" />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <LoginSocialGoogle
                                                                    client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                                    scope="https://www.googleapis.com/auth/userinfo.profile"
                                                                    onResolve={({ provider, data }: IResolveParams) => this.handleSocialLogin(provider, data)}
                                                                    onReject={(error) => toast.error(error.message, { position: toast.POSITION.TOP_LEFT })}
                                                                >
                                                                    <img src="/assets/images/Google.svg" alt="google" />
                                                                </LoginSocialGoogle>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <p>
                                                        Not a Customer?&nbsp;&nbsp;<Link to="/sign-up">Sign Up</Link>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="img-sec">
                                                    <img src="/assets/images/sign-in.png" alt="login" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginFooter />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onSignIn: userActions.SignIn,
    onGetAuthUser: userActions.GetAuthUser,
    onSocialSignIn: userActions.SocialSignIn,
    onForgotPassword: userActions.ForgotPassword,
    
};
export default connect(mapStateToProps, mapDispatchToProps)(signin);