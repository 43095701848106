import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** Getproduct Action ***/
export const Getproduct_START = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.Getproduct_START,
    };
};

export const Getproduct_SUCCESS = (response) => {
    return {
        error: false,
        loader: false,
        productData: response,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.Getproduct_SUCCESS,
    };
};
export const Getproduct_FAIL = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.Getproduct_FAIL,
    };
};
export const Getproduct = (search, website) => {
    console.log(search, "asasasassssssssssssssssssssssssssssssss")
    return (dispatch) => {
        dispatch(Getproduct_START());

        let search_item;
        if (search?.type) {
            search_item = { search: search?.search_input, categories: search?.search_input, website: website }
            if (search.type == "categories") {
                search_item = { search: search?.search_input, categories: search?.search_input, website: website }
            } else {
                search_item = { categoryId: search.categoryId, categoriesProducts: search.categoriesProducts, website: website }
            }
        } else {
            search_item = { search: search, website: website }
        }
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/Getproduct`,
            params: search_item,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(Getproduct_SUCCESS(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(Getproduct_FAIL("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const getCategoriesList = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            headers: {
                'Content-Type': "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/get-product-categories`
        }).then(function (response) {
            // dispatch(Getproduct_SUCCESS(response.data));
            return response.data;
        })
            .catch(function (error) {
                // dispatch(Getproduct_FAIL("Something went wrong, Please try again."));
                return error;
            });
    }

}
