import React from "react";

import { useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Cart from "../../components/Application/Account/Cart";
import Checkout from "../../components/Application/Account/Checkout";
import Wishlist from "../../components/Application/Account/Wishlist";
import Profile from "../../containers/Page/Account";
import Completed_Orders from "../../containers/Page/Completed_orders";
import IN_Progress_Orders from "../../containers/Page/InProgressOrders";
import Complaints from "../../containers/Page/Complaints";

const Account = (props) => {
	const Renderview = () => {
		const location = useLocation();
		if (location.pathname === "/cart" || location.pathname === "/cart/") {
			return <Cart />
		}
		if (location.pathname === "/checkout" || location.pathname === "/checkout") {
			return <Checkout />
		}
		if (location.pathname === "/wishlist" || location.pathname === "/wishlist") {
			return <Wishlist />
		}
		if (location.pathname === "/account" || location.pathname === "/account") {
			return <Profile />
		}
		if (location.pathname === "/completed-orders" || location.pathname === "/completed-orders") {
			return <Completed_Orders />
		}
		if (location.pathname === "/view-order-details" || location.pathname === "/view-order-details") {
			return <IN_Progress_Orders location="View-orders" />
		}	
		if (location.pathname === "/in-progress-orders" || location.pathname === "/in-progress-orders") {
			return <IN_Progress_Orders location="progress-orders" />
		}
		if (location.pathname === "/complaints" || location.pathname === "/complaints") {
			return <Complaints />
		}
	};

	return (
		<React.Fragment>			
			{Renderview()}
		</React.Fragment>
	);
}
export default Account;
