import { Navigate } from "react-router-dom";
import * as actions from "./actions";

const AdminProtectRoute = ({ isLoggedIn, children }) => {
    if (actions.isAdminAuthorize()) {
        return <Navigate to="/currency-rate" />;
    } else {
        return children;
    }
};
export default AdminProtectRoute;