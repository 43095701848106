import React from "react";

import { useLocation, Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import SignIn from "./signin";
import Dashboard from "../../Page/SuperAdmin/Dashboard";
import ManageCustomer from "../../Page/SuperAdmin/ManageCustomer";
import OrderList from "../../Page/SuperAdmin/OrderList";
import Complaints from "../../Page/SuperAdmin/Complaints";
import AddUser from "../../Page/SuperAdmin/AddUser";
import ViewUser from "../../Page/SuperAdmin/ViewUser";
import ViewOrder from "../../Page/SuperAdmin/ViewOrder";

import { useState, useEffect } from "react";
import Loader from "../../../components/Utility/Loader";
import ViewComplaints from "../../Page/SuperAdmin/ViewComplaints";


const Account = (props) => {
	const location = useLocation();
	const [url, setUrl] = useState(null);
	const [isLoad, setLoad] = useState(false);
	useEffect(() => {
		setUrl(location.pathname);
	}, [location]);

	const isLoading = async () => {
		setLoad(true);
		setTimeout(() => {
			setLoad(false);
		},500);
	}
	const Renderview = () => {
		if (location.pathname === "/super-admin-login" || location.pathname === "/super-admin-login/") {
			return <SignIn />
		}
		if (location.pathname === "/super-admin-dashboard") {
			return <Dashboard  />
		}
		if (location.pathname === "/super-admin-manage-customer" || location.pathname === "/super-admin-manage-customer/") {
			return <ManageCustomer  />
		}
		if (location.pathname === "/super-admin-order-list" || location.pathname === "/super-admin-order-list/") {

			return <OrderList />
		}
		if (location.pathname === "/super-admin-complaints" || location.pathname === "/super-admin-complaints/") {

			return <Complaints />
		}
		if (location.pathname === "/super-admin-add-user") {
			return <AddUser renderTo="Add user" />
		}
		if (location.pathname.match(/\/(super-admin-edit-user.+)/)) {
			return <AddUser renderTo="Edit user" location={location} />
		}
		if (location.pathname.match(/\/(super-admin-view-user.+)/)) {
			return <ViewUser location={location} loader={isLoading} />;
		}
		if (location.pathname.match(/\/(super-admin-view-order.+)/)) {
			return <ViewOrder location={location} />;
		}
		if (location.pathname.match(/\/(super-admin-view-complaint.+)/)) {
			return <ViewComplaints location={location} />;
		}

		// const location = useLocation();
	};
	const style = {
		backgroundColor: '#fff',
		color: '#0A2558'
	}

	return (

		<React.Fragment>
			{isLoad === true ? <Loader /> : ''}
			<div className="">
				{location.pathname !== "/super-admin-login" ?
					<div className="sidebar">
						<div className="logo-details">
							<i className='bx bxl-c-plus-plus'></i>
							<span className="logo_name">Super Admin</span>
						</div>	
						<ul className="nav-links">
							<li onClick={isLoading}>
								<Link to="/super-admin-dashboard" className={url === "/super-admin-dashboard" ? 'active' : location.pathname.match(/\/(super-admin-view-user.+)/) ? 'active' : ''}>
									<i className='bx bx-home' ></i>
									<span className="links_name">Dashboard</span>
								</Link>
							</li>
							<li onClick={isLoading}>
								<Link to="/super-admin-manage-customer" className={url === "/super-admin-manage-customer" ? 'active' : location.pathname.match(/\/(super-admin-view-user.+)/) ? 'active' : ''}>
									<i className='bx bx-grid-alt' ></i>
									<span className="links_name">Manage Customers</span>
								</Link>
							</li>
							<li onClick={isLoading}>
								<Link to="/super-admin-order-list" className={url === "/super-admin-order-list" ? 'active' : location.pathname.match(/\/(super-admin-view-order.+)/) ? 'active' : ''}>
									<i className='bx bx-box' ></i>
									<span className="links_name">Manage Orders</span>
								</Link>
							</li>
							<li onClick={isLoading}>
								<Link to="/super-admin-complaints" className={url === "/super-admin-complaints" ? 'active' : ''}>
									<i className='bx bx-list-ul' ></i>
									<span className="links_name">Manage complaints</span>
								</Link>
							</li>
						</ul>
					</div> : ''}
				{Renderview()}
				<footer>
					<div className="container-fluid mt-4">
						<div className="row">
							<div className="col-lg-12" style={style}>
								<div className="text-center">
									&copy; copyright by &#9829; Shiprofly
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</React.Fragment>
	);
}
export default Account;
