import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import App from "./App";
import AuthReducer from "./reducers/Auth";
import ProductReducer from "./reducers/Product";
import AccountReducer from "./reducers/Account";
import OrderReducer from "./reducers/Orders";

import reportWebVitals from "./reportWebVitals";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    Auth: AuthReducer,
    Product: ProductReducer,
    Account: AccountReducer,
    Order: OrderReducer,
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();