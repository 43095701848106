import { Navigate } from "react-router-dom";
import * as actions from "./actions";

const AuthRoute = ({ isLoggedIn, children }) => {
    if (actions.isAuthorize()) {
        return <Navigate to="/" />;
    } else {
        return children;
    }
};
export default AuthRoute;