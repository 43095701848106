import React from "react";
import { Modal } from "react-bootstrap";

import Validations from "../../../components/Utility/Validations";
class ForgotPasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forgotpassword_form: {
                email: {
                    label: "Email address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
            },
            forgot_password_modal: false,
        };
    }

    inputChangeHandler(event, identifier) {
        event.preventDefault();

        const update_forgotpassword_form = { ...this.state.forgotpassword_form };
        const form_element = { ...update_forgotpassword_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(event, form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_forgotpassword_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_forgotpassword_form) {
            is_form_valid = update_forgotpassword_form[identifier].valid && is_form_valid;
        }

        this.setState({
            forgotpassword_form: update_forgotpassword_form,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_forgotpassword_form = this.state.forgotpassword_form;
        for (let key in update_forgotpassword_form) {
            let form_element = update_forgotpassword_form[key];
            let validate_element = Validations(event, form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_forgotpassword_form[key] = form_element;
        }
        this.setState({ forgotpassword_form: update_forgotpassword_form });

        if (is_form_valid) {
            const form_data = new FormData();
            for (let key in this.state.forgotpassword_form) {
                form_data.append(key, this.state.forgotpassword_form[key].value);
            }
            this.props.ForgotPassword(form_data);
        }
    };

    render() {
        const { forgotpassword_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="forgot_password_modal"
                    show={this.props.forgot_password_modal}
                    onHide={() => {
                        this.props.forgot_password_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Password Recovery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12 fv-row mb-4 mt-8 text-center">
                                <p> Enter your email and a new password will be sent to your email </p>
                                <form onSubmit={this.SubmitHandler} autoComplete="off">
                                    <div className={"fv-row forget-email" + (!forgotpassword_form.email.valid && forgotpassword_form.email.onBlur_out ? " error-field" : "")}>
                                        <input
                                            type={forgotpassword_form.email.type}
                                            className="form-control form-control-lg form-control-solid"
                                            placeholder={forgotpassword_form.email.placeholder}
                                            value={forgotpassword_form.email.value ? forgotpassword_form.email.value : ""}
                                            onChange={(event) => this.inputChangeHandler(event, "email")}
                                            onBlur={(event) => {
                                                forgotpassword_form.email.onBlur_out = true;
                                                this.setState({
                                                    forgotpassword_form: forgotpassword_form,
                                                });
                                            }}
                                        />
                                        {!forgotpassword_form.email.valid && forgotpassword_form.email.onBlur_out && <div className="error field-error">{forgotpassword_form.email.error_msg}</div>}
                                    </div>
                                    <button type="submit" className="hvr-grow btn btn-lg btn-primary rounded-50 w-100 mb-8 ">
                                        Forgot password
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default ForgotPasswordModal;
