import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import * as productActions from "../actions/product";
import * as userActions from "../actions/account";
class LoginHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_input: "",
            search_loader: false,
            isActive: false,
            showHeader: false,
            showHeaderDashboard: false,
            cartItemCount: null,
            whishListItemCount:null,
            user_info: null,
            website: null,
        };
    }

    // componentDidMount() {
    //     this.props.onCart().then((response) => {
    //         if (response.message === "Unauthorized") {
    //             this.setState({
    //                 user_info: false
    //             });
    //         } else {
    //             this.setState({
    //                 cartItemCount: response.data?.length,
    //                 user_info: response.user_info,
    //             });
    //         }
    //     });
        

    //     this.props.onWishlist().then((response) => {
    //         if(response?.message == "Unauthorized"){
    //             this.setState({
    //                 user_info: false
    //             })
    //         }else{

    //             this.setState({
    //                 whishListItemCount: response?.length,
    //                 user_info: response?.user_info,
    //             })
    //         }
    //     })
    //     if (this.props?.location?.state?.search_input) {
    //         this.setState({
    //             search_input: this.props?.location?.state?.search_input,
    //         });
    //     }
    // }

    async componentDidMount() {
        try {
            this.setState({website: "http://www.taobao.com/"})
            const cartResponse = await this.props.onCart();
    
            const wishlistResponse = await this.props.onWishlist();
    
            this.setState({
                cartItemCount: cartResponse.data?.length,
                whishListItemCount: wishlistResponse?.length,
                user_info: cartResponse.user_info || wishlistResponse?.user_info,
                loading: false, 
            });
    
            if (this.props?.location?.state?.search_input) {
                this.setState({
                    search_input: this.props?.location?.state?.search_input,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    
    handleDropdownChange = async (event) => {
        this.setState({website: event.target.value})
        this.props.handleDropdownChange(event.target.value)
    
        // try {
        //     const getProductResponse = await axios.get('/api/Getproduct', {
        //         params: {
        //             search: selectedProvider,
        //             selectedProvider: selectedProvider,
        //         },
        //     });
    
        //     console.log(getProductResponse.data);
    
        // } catch (error) {
        //     // Handle errors
        //     console.error('Error calling Getproduct API:', error);
        // }
    };
    
    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps);
        if(nextProps?.cartItemCount){
            this.setState({
                cartItemCount: nextProps.cartItemCount
            })
        }
        if(nextProps.cartItemCount === 0){
            this.setState({
                cartItemCount: ''
            })
        }
        
    }

    LoginHeaderSearchLoaderStart() {
        this.setState({ search_loader: true });
    }

    LoginHeaderSearchLoaderStop() {
        this.setState({ search_loader: false });
    }

    SearchSubmitHandler = (event) => {
        event.preventDefault();

        const { search_input } = this.state;
        if (this.props?.page_type && (this.props?.page_type === "product_list" || this.props?.page_type === "product_details")) {
            this.props.propsGetProduct(search_input);
        } else {
            this.setState({ search_loader: true });
            if(search_input){
                this.props.getProduct(search_input, this.state.website).then((response) => {
                    // console.log(response, "++++++++++++++++response login handler++++++++++++++++++++++++++++")
                    this.setState({ search_loader: false });
                    if (response.success === true) {
                        if (response.data?.item) {
                            this.setState({
                                is_redirect: true,
                                redirect_url: `/product-detail/search?${search_input}`,
                                redirect_state: {
                                    search_input: search_input,
                                    product_item: response.data?.item,
                                },
                            });
                        } else {
                            if (response.data.length > 0) {
                                this.setState({
                                    is_redirect: true,
                                    redirect_url: `/product-list/search?${search_input}`,
                                    redirect_state: {
                                        search_input: search_input,
                                        product_details: response.data,
                                        currencyRate: response.currencyRate
                                    },
                                });
                            }else{
                                
                                toast.error("No Result Found", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                            
                        }
                    } else {
                        toast.error(response?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                });
            }else{
                this.setState({ search_loader: false });
                toast.error("Please add some text or link to search items", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

        }
    };

    setShowHeader = (value) => {
        this.setState({
            showHeader: value
        })
    }
    setActive = (value) => {
        this.setState({
            isActive: value
        })
    }

    setShowDashboard = (value) => {
        if(value){
            document.body.classList.add('dashboard-open');
        }else{
            document.body.classList.remove('dashboard-open');
        }
        this.setState({
            showHeaderDashboard: value
        })
    }

    render() {
        const { search_input, search_loader, isActive, showHeader, cartItemCount,whishListItemCount, user_info, showHeaderDashboard } = this.state;
        const { handleDropdownChange } = this.props;
        return (
            <React.Fragment>
                <ToastContainer />
                {this.state.is_redirect ? <Navigate to={this.state.redirect_url} state={this.state.redirect_state} /> : null}

                <div className={isActive ? 'mobile-view show-search-section' : 'mobile-view'} >
                    <form onSubmit={this.SearchSubmitHandler}>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            name="search"
                            value={search_input}
                            placeholder="Search or paste product link here"
                            onChange={(event) => {
                                this.setState({
                                    search_input: event.target.value,
                                });
                            }}
                        />
                        <button type="submit">
                            {search_loader ? (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            ) : (
                                <i className="fa fa-search" aria-hidden="true"></i>
                            )}
                        </button>
                        {/* <img className="remove-search" onClick={() => this.setActive(false)} src="https://development.brstdev.com/easycuts/wp-content/themes/hello-elementor-child/assets/images/cross.png" alt="Header" /> */}
                        <img className="remove-search" onClick={() => this.setActive(false)} src="../../public/assets/images/cross.svg" alt="Header" />
                    </form>

                </div>
                <header className="header-main desktop-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="site-logo">
                                    <a href="/">
                                        <img src="/assets/images/logo.png" className="logo-header" alt="logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-3 new_search">
                                <div className="right-section">
                                    <div className="search">
                                        <form onSubmit={this.SearchSubmitHandler}>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                name="search"
                                                value={search_input}
                                                placeholder="Search or paste product link here"
                                                onChange={(event) => {
                                                    this.setState({
                                                        search_input: event.target.value,
                                                    });
                                                }}
                                            />
                                            <button type="submit">
                                                {search_loader ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                ) : (
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                )}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col new-search">
                                <div className="search-icon">
                                    <button type="submit">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div className="dropdown">
                                    <div className="select">
                                    <select
                                        className="select-styled"
                                        onChange={this.handleDropdownChange}
                                    >
                                        <option value="http://www.taobao.com/">http://www.taobao.com</option>
                                        <option value="http://1688.com/">http://1688.com</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="button-sec">
                                    <div className="btnns">
                                        {/* {user_info ? (
                                            <a onClick={() => this.setShowDashboard(true)} className="profile">
                                                <img src="/assets/images/profile.png" alt="Sign-in" />
                                                <span>Hi, {user_info.full_name}</span>
                                            </a>
                                        ) : (
                                            <Link to="/login" className="sign-in">
                                                <img src="/assets/images/login.png" alt="Sign-in" />
                                                Sign In
                                            </Link>
                                        )} */}

                                        {this.state.loading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <div>
                                                {this.state.user_info ? (
                                                    <a onClick={() => this.setShowDashboard(true)} className="profile">
                                                        <img src="/assets/images/profile.png" alt="Sign-in" />
                                                        <span>Hi, {this.state.user_info.full_name}</span>
                                                    </a>
                                                ) : (
                                                    <Link to="/login" className="sign-in">
                                                        <img src="/assets/images/login.png" alt="Sign-in" />
                                                        Sign In
                                                    </Link>
                                                )}
                                            </div>
                                        )}
                                        <Link to="/cart" className="cart">
                                            <img src="/assets/images/cart.png" alt="cart" />
                                            {cartItemCount > 0 ? <span className="cart-item-count">{cartItemCount}</span>: ''}

                                            Cart
                                        </Link>
                                        <Link to="/wishlist" className="whish-list">
                                            <img src="/assets/images/wishlist.png" alt="cart" />
                                            {whishListItemCount > 0 ? <span className="cart-item-count">{whishListItemCount}</span>: ''}
                                            Wish
                                        </Link> 
                                    </div>

                                </div>
                            </div>
                            <div className="col">
                                <div className="buy-btn">
                                    <Link to="/how-to-buy" className="btn how-to-buy">
                                        How To Buy
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </header>

                <header className="header-main mobile_header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-4">
                                <div className="site-logo">
                                    <a href="/">
                                        <img src="/assets/images/logo.png" className="logo-header" alt="logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="main-mobile-buttons">
                                    {/* <div className="right-section">
                                        <div className="search-icon">
                                            <button type="submit" onClick={() => this.setActive(true)}>
                                                <i className="fa fa-search" aria-hidden="true">
                                                </i>
                                            </button>
                                        </div>
                                    </div> */}
                                    <div className="right-section">
                                        <form onSubmit={this.SearchSubmitHandler}>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                name="search"
                                                value={search_input}
                                                placeholder="Search or paste product link here"
                                                onChange={(event) => {
                                                    this.setState({
                                                        search_input: event.target.value,
                                                    });
                                                }}
                                            /> <button type="submit" className="mobile-search-icon" >
                                                {search_loader ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                ) : (
                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                )}
                                            </button>
                                        </form>
                                    </div>
                                    <button className="toggle" onClick={() => this.setShowHeader(true)}><i className="fa fa-bars"></i></button>
                                </div>
                                <div className={showHeader ? 'inner_content mobile_options' : 'inner_content'}>
                                    <img className="cross" src="/assets/images/delete-sign.png" alt="cross" onClick={() => this.setShowHeader(false)} />
                                    <div className="button-sec">
                                        <div className="btnns">
                                            {user_info
                                                ?
                                                <>

                                                    <Link to="/logout" className="sign-in">
                                                        <img src="/assets/images/log-out.png" alt="Sign-out" />Sign Out
                                                    </Link>

                                                </>
                                                :
                                                <Link to="/login" className="sign-in">
                                                    <img src="/assets/images/login.png" alt="Sign-in" />Sign In
                                                </Link>
                                            }
                                            <Link to="/cart" className="cart" >
                                                <img src="/assets/images/cart.png" alt="cart" />Cart
                                            </Link>
                                            <Link to="/wishlist" className="whish-list">
                                            <img src="/assets/images/wishlist.png" alt="cart" />
                                                Wish
                                            </Link>
                                        </div>
                                        <div className="buy-btn">
                                            <a className="btn btn-primary" href="/how-to-buy">
                                                How To Buy
                                            </a>
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <div className="select">
                                            <select className="select-styled">
                                                <option className="option" value="http://www.taobao.com/">
                                                    http://www.taobao.com/
                                                </option>
                                                <option value="http://www.taobao.com/">
                                                    http://www.taobao.com/
                                                </option>
                                                <option value="http://www.taobao.com/">
                                                    http://www.taobao.com/
                                                </option>
                                                <option value="http://www.taobao.com/">
                                                    http://www.taobao.com/
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <header className={showHeaderDashboard ? 'dashboard dashboard-active' : 'dashboard'}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-8">
                                <div className={showHeaderDashboard ? 'dashboard_content dashboard_options' : 'dashboard_content'}>

                                    <div className="">
                                        <div className="">
                                            <div className="">
                                            <h3 className="account" data-test="modal-drawer-heading" tabIndex="-1">Account</h3>
                                            <div className="styles__IconButtonCloseWrapper-sc-1uliqpu-6 dbMvVR">
                                                <button onClick={() => this.setShowDashboard(false)} className="cross-button">
                                                    <svg width="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-icon-name="XMark" focusable="false" role="presentation" className="style__IconWrapper-sc-7xjkag-0 jYXcWu">
                                                        <path d="M22 3.4 13.4 12l8.6 8.6-1.4 1.4-8.6-8.6L3.4 22 2 20.6l8.6-8.6L2 3.4 3.4 2l8.6 8.6L20.6 2 22 3.4Z" fill="#fff"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="dashboard-content">
                                        <ul className="dashboard-content-ul">
                                        <li><Link to={"/account"}><span className=""><span>Edit Profile</span></span></Link></li>
                                        <li><Link to={"/completed-orders"}><span className=""><span>Completed Orders</span></span></Link></li>
                                        <li><Link to={"/in-progress-orders"}><span className=""><span>In-Progress Orders</span></span></Link></li>
                                        <li><Link to={"/complaints"}><span className=""><span>Complaints</span></span></Link></li>
                                        <li><Link to={'/logout'}><span className=""><span>Sign Out</span></span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="header-bottom">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="heading">
                                    <h3>Buy Wholesale From China</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        productData: state.Product.productData,
    };
}
const mapDispatchToProps = {
    onCart: userActions.GetCart,
    onWishlist: userActions.GetWishlist,
    getProduct: productActions.Getproduct,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LoginHeader);
