import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import "../../assets/css/profile.css";
import * as userActions from "../../actions/index";

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_info: null
        };
        
    }
    componentDidMount() {
        document.body.classList.remove('dashboard-open');
        window.scrollTo(0, 0);
        this.props.onGetAuthUser().then((response) => {
            if (response.success === true) {
                this.setState({
                    user_info: response.user_info
                })
            }
        });
    }
    render() {
        const { user_info} = this.state;
        return (
            <>
                <React.Fragment>
                        <LoginHeader />
                        <div className=" profile-container container-xl px-4 mt-4">
                            <nav className="nav nav-borders">
                                <a className="nav-link active ms-0" href="" target="">Profile</a>
                                {/* <a className="nav-link" href="https://www.bootdey.com/snippets/view/bs5-profile-billing-page" target="__blank">Billing</a>
                                <a className="nav-link" href="https://www.bootdey.com/snippets/view/bs5-profile-security-page" target="__blank">Security</a>
                                <a className="nav-link" href="https://www.bootdey.com/snippets/view/bs5-edit-notifications-page"  target="__blank">Notifications</a> */}
                            </nav>
                            <hr className="mt-0 mb-4" />
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card mb-4 mb-xl-0">
                                        <div className="card-header">Profile Picture</div>
                                        <div className="card-body text-center" style={{ padding: '55px' }}>
                                            <img className="img-account-profile rounded-circle mb-2" src="http://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
                                            <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                                            <button className="btn btn-primary" type="button">Upload new image</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8">
                                    <div className="card mb-4">
                                        <div className="card-header">Account Details</div>
                                        <div className="card-body">
                                            <form>
                                                <div className="row gx-3 mb-3">
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" for="inputFirstName">First name</label>
                                                        <input className="form-control" id="inputFirstName" type="text" placeholder="Enter your first name" value={user_info?.full_name} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" for="inputLastName">Last name</label>
                                                        <input className="form-control" id="inputLastName" type="text" placeholder="Enter your last name" value={user_info?.full_name} />
                                                    </div>
                                                </div>
                                                <div className="row gx-3 mb-3">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" for="inputEmailAddress">Email address</label>
                                                    <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value={user_info?.email}/>
                                                </div>
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" for="inputPhone">Phone number</label>
                                                        <input className="form-control" id="inputPhone" type="tel" placeholder="Enter your phone number" value={user_info?.phone_no} />
                                                    </div>
                                                    
                                                </div>
                                                <div className="row gx-3 mb-3">
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" for="inputPassword">Password</label>
                                                        <input className="form-control" id="inputPassword" type="password" placeholder="Enter your password"  />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="small mb-1" for="inputConfirmPassword">Confirm Password</label>
                                                        <input className="form-control" id="inputConfirmPassword" type="password" name="birthday" placeholder="Enter your password again" />
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary" type="button" style={{ float: 'right' }}>Save changes</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                </React.Fragment>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}

const mapDispatchToProps = {
    onGetAuthUser: userActions.GetAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Account);
