import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";


export const AddWishlistStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.AddWishlist_START,
    };
};

export const AddWishlistSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: Master_actionType.Wishlist_SUCCESS,
    };
};

export const AddWishlistFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.Wishlist_FAIL,
    };
};

export const GetWishlistStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.GetWishlist_START,
    };
};

export const GetWishlistSuccess = () => {
    return {
        error: null,
        loader: false,
        type: Master_actionType.GetWishlist_SUCCESS,
    };
};

export const GetWishlistFail = () => {
    return {
        error: null,
        loader: false,
        type: Master_actionType.GetWishlist_FAIL,
    };
};

export const QtyUpStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.QtyUp_START,
    };
}

export const QtyUpSuccess = (response) => {
    return {
        token: false,
        loader: false,
        data: response,
        type: Master_actionType.QtyUp_SUCCESS,
    };
};

export const QtyUpFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.QtyUp_FAIL,
    };
};

export const AddWishlist = (form_data) => {
    return (dispatch) => {
        dispatch(AddWishlistStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/AddWishlist`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(AddWishlistSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddWishlistFail("Something went wrong, Please try again."));
                return error;
            });
    };
}

export const GetWishlist = () => {
    return (dispatch) => {
        dispatch(GetWishlistStart());
        return axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetWishlist`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(GetWishlistSuccess("Get Cart successfully !!!"));
                return response.data.data;
            })
            .catch(function (error) {
                dispatch(GetWishlistFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 

export const WishlistQtyUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(QtyUpStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/wishlist-qty-update`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(QtyUpSuccess(response.data.data));
                console.log(response.data.data , "QtyUpdate Response")
                return response.data.data;
            })
            .catch(function (error) {
                dispatch(QtyUpFail("Something went wrong, Please try again."));
                return error;
            });
    };
}

export const RemoveFromWishlist = (cartId) => {
    return (dispatch) => {
        // dispatch(QtyUpStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/remove-from-wishlist`,
            params: { cartId: cartId },
        })
            .then(function (response) {
                // dispatch(QtyUpSuccess(response.data.data));
                console.log(response.data , "Remove Product")
                return response.data;
            })
            .catch(function (error) {
                dispatch(QtyUpFail("Something went wrong, Please try again. RemoveProduct"));
                return error;
            });
    };
}
