import React, { Component } from 'react'
import { connect } from "react-redux";

import "../../../assets/css/superAdmin.css";
import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader.js";
import Validations from "../../../components/Utility/Validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";

class AddUser extends Component {
  // static propTypes = {
  //   prop: PropTypes
  // }
  constructor(props) {
    super(props);
    const {renderTo} = this.props;
    if(renderTo ==="Edit user"){
      this.state = {
        register_form: {
          full_name: {
            type: "text",
            label: "Full name *",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "Full name",
            validations: { required: true },
          },
          email: {
            type: "email",
            label: "Email",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "Enter your email",
            validations: { required: true, email: true },
          },
          phone_no: {
            type: "text",
            label: "Phone number",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "Phone Number",
            validations: { required: true, phone_no: true },
          },
          password: {
            type: "password",
            label: "Password",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "Enter your password",
            validations: { required: false },
          },
        },
        eye: 'fa fa-eye-slash',
        Role: {
          value: 'customer'
        },
        userData: ''
      };
    }else{
      this.state = {
        register_form: {
          full_name: {
            type: "text",
            label: "Full name *",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "Full name",
            validations: { required: true },
          },
          email: {
            type: "email",
            label: "Email",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "Enter your email",
            validations: { required: true, email: true },
          },
          phone_no: {
            type: "text",
            label: "Phone number",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "Phone Number",
            validations: { required: true, phone_no: true },
          },
          password: {
            type: "password",
            label: "Password",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "Enter your password",
            validations: { required: true },
          },
        },
        eye: 'fa fa-eye-slash',
        Role: {
          value: 'customer'
        },
        userData: ''
      };
    }
  }

  componentDidMount(){
    let search = this.props.location;
    if(search){
      const id = search.pathname.replace('/super-admin-edit-user/', '');
      const form_data = new FormData();
      form_data.append("id", id)
      if(id){
        this.props.ViewUser(form_data).then((response) => {
            if (response.success === true) {
              let user = response.data;
                this.setState({
                    userData: user,
                })
            } else {
                console.log("error")
            }
        })
      }
    }
  }


  inputChangeHandler(event, identifier) {
    const {renderTo} = this.props;
    if(renderTo === "Edit user"){
      let user = this.state.userData;
      user[identifier]=event.target.value
      this.setState({
        userData: user,
      })
    }
    // this.state.userData[identifier] = event.target.value
    const update_register_form = { ...this.state.register_form };
    const form_element = { ...update_register_form[identifier] };
    form_element.value = event.target.value;

    // Check Validation
    let validate_element = Validations(event, form_element);
    form_element.valid = validate_element.valid;
    form_element.error_msg = validate_element.error_msg;

    update_register_form[identifier] = form_element;
    this.setState({ register_form: update_register_form });
  }

  SubmitHandler = (event) => {
    event.preventDefault();
    const {renderTo} = this.props;

    if (renderTo !=="Edit user") {
      let is_form_valid = true;
      const update_register_form = this.state.register_form;
      for (let key in update_register_form) {
        let form_element = update_register_form[key];
        let validate_element = Validations(event, form_element);
  
        is_form_valid = validate_element.valid === false ? false : is_form_valid;
        form_element.onBlur_out = true;
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
  
        update_register_form[key] = form_element;
      }
      this.setState({ register_form: update_register_form });
      if(is_form_valid){
        const form_data = new FormData();
        form_data.append("user_type", this.state.Role.value);
        for (let key in this.state.register_form) {
          form_data.append(key, this.state.register_form[key].value);
        }
        this.props.CreateUser(form_data).then((response) => {
          if (response.success === true) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
              this.setState({
                isAuthenticated: true,
                redirect_url: "/super-admin-manage-customer",
              });
            }, 1000);
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
      }
    }else{
      const form_data = new FormData(event.target);
      form_data.append("full_name",event.target[0].value)
      form_data.append("email",event.target[1].value)
      form_data.append("phone_no",event.target[2].value)
      form_data.append("id",this.state.userData.id)
      this.props.UpdateUser(form_data).then((response) => {
        if (response.success === true) {
          toast.success(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            this.setState({
              isAuthenticated: true,
              redirect_url: "/super-admin-manage-customer",
            });
          }, 1000);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
    }

  }
  handleClickEye = () => {
    if (this.state.register_form.password.type === 'password') {
      this.setState(prevState => ({
        register_form: {
          ...prevState.register_form,
          password: {
            ...prevState.register_form.password,
            type: 'text',
          }
        },
        eye: 'fa fa-eye'

      }))
    } else {
      this.setState(prevState => ({
        register_form: {
          ...prevState.register_form,
          password: {
            ...prevState.register_form.password,
            type: 'password',
          }
        },
        eye: 'fa fa-eye-slash'

      }))
    }

  }
  render() {

    const { register_form, eye ,userData} = this.state;

    // let rForm = register_form.password.validations.required = false;
    return (
      <>
        <React.Fragment>
          <ToastContainer />
          {/* {userData ? this.setState.register_form.password.validations(false): ''} */}

          {this.state.loader ? <Loader /> : null}
          {this.props.loader ? <Loader /> : null}
          {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
          <section className="home-section">
            <nav>
              <div className="sidebar-button">
                <i className='bx bx-menu sidebarBtn'></i>
                <span className="dashboard">Add User</span>
              </div>
            </nav>

            <div className="home-content">
              <div className="sales-boxes">
                <div className="recent-sales box">
                  <div className="title ">Add User</div>
                  <form className="row" onSubmit={this.SubmitHandler}>
                    <div className="form-group col-lg-6 my-3">
                      <label htmlFor="Full name">{register_form.full_name.label}</label>
                      <input
                        type={register_form.full_name.type}
                        className="form-control form-control-lg form-control-solid"
                        placeholder={register_form.full_name.placeholder}



                        // value={register_form.full_name.value ? register_form.full_name.value : ''}
                        // userData ? userData.full_name :
                        value={(userData) ? userData.full_name : register_form.full_name.value ? register_form.full_name.value : ''}

                        onChange={(event) => this.inputChangeHandler(event, "full_name")}
                        onBlur={(event) => {
                          register_form.full_name.onBlur_out = true;
                          this.setState({
                            register_form: register_form,
                          });
                        }}
                      />

                      {!register_form.full_name.valid && register_form.full_name.onBlur_out && <div className="error field-error">{register_form.full_name.error_msg}</div>}
                    </div>

                    <div className="form-group col-lg-6 my-3">
                      <label htmlFor="Email">{register_form.email.label}</label>

                      <input
                        type={register_form.email.type}
                        className="form-control form-control-lg form-control-solid"
                        placeholder={register_form.email.placeholder}
                        value={(userData) ? userData.email : register_form.email.value ? register_form.email.value : ''}
                        // value={register_form.email.value ? register_form.email.value :userData ? userData.email : ''}
                        onChange={(event) => this.inputChangeHandler(event, "email")}
                        onBlur={(event) => {
                          register_form.email.onBlur_out = true;
                          this.setState({
                            register_form: register_form,
                          });
                        }}
                      />

                      {!register_form.email.valid && register_form.email.onBlur_out && <div className="error field-error">{register_form.email.error_msg}</div>}
                    </div>
                    <div className="form-group col-lg-6 my-3">
                      <label htmlFor="Phone no">{register_form.phone_no.label}</label>

                      <input
                        type={register_form.phone_no.type}
                        className="form-control form-control-lg form-control-solid"
                        placeholder={register_form.phone_no.placeholder}

                        value={(userData) ? userData.phone_no : register_form.phone_no.value ? register_form.phone_no.value : ''}


                        // value={register_form.phone_no.value ? register_form.phone_no.value : userData ? userData.phone_no : ''}
                        onChange={(event) => this.inputChangeHandler(event, "phone_no")}
                        onBlur={(event) => {
                          register_form.phone_no.onBlur_out = true;
                          this.setState({
                            register_form: register_form,
                          });
                        }}
                      />
                      {!register_form.phone_no.valid && register_form.phone_no.onBlur_out && <div className="error field-error">{register_form.phone_no.error_msg}</div>}
                    </div>
                    {!userData ? <div className="form-group col-lg-6 my-3">
                      <label htmlFor="Password">{register_form.password.label}</label>

                      <input
                        id="id_password"
                        type={register_form.password.type}
                        className="form-control form-control-lg form-control-solid"
                        placeholder={register_form.password.placeholder}
                        value={(userData) ? userData.password : register_form.password.value ? register_form.password.value : ''}

                        // value={register_form.password.value ? register_form.password.value : ""}
                        onChange={(event) => this.inputChangeHandler(event, "password")}
                        onBlur={(event) => {
                          register_form.password.onBlur_out = true;
                          this.setState({
                            register_form: register_form,
                          });
                        }}
                      />
                      <i className={eye}
                        id="togglePassword"
                        onClick={() => this.handleClickEye()}>
                      </i>
                      {!register_form.password.valid && register_form.password.onBlur_out && <div className="error field-error">{register_form.password.error_msg}</div>}
                    </div>
                    :'' }
                       <div className={`form-group ${!userData ?` col-lg-12`: 'col-lg-6 '} mt-5 my-3 text-end`}>
                      <label htmlFor="" className='form-control-solid'>
                       
                      </label>
                      <button type="submit" className="btn btn-primary sign-in">
                        Create
                      </button>
                    </div>
                    <div className="form-group ">
                      <div className="invalid-feedback"></div>
                    </div>
                  </form>

                </div>

              </div>
            </div>
          </section>


        </React.Fragment>
      </>
    )
  }
}


function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error,
};
}


const mapDispatchToProps = {
  CreateUser: userActions.CreateUser,
  ViewUser: userActions.ViewUser,
  UpdateUser: userActions.UpdateUser,
};


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddUser);