import React from "react";
import { connect } from "react-redux";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import * as userActions from "../../../actions/index";
import * as userActionsCart from "../../../actions/account";
import * as AccountActions from "../../../actions/account";
import Loader from "../../../components/Utility/Loader";
import LoginHeader from "../../../components/LoginHeader";
import LoginFooter from "../../../components/LoginFooter";
import getSymbolFromCurrency from 'currency-symbol-map';

class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carousel_responsive: {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: { max: 4000, min: 3000 },
                    items: 3,
                },
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                },
                tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 3,
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 3,
                },
            },
            cartItemCount: null,
            search_input: null,
            quantity: 1,
            loader: false,
            product_detail: {},
            valid: false,
            like: false,
            product_sku_requirements: null,
            product_properties: {},
            active_properties: {},
            requirements_validation: {},
            Unauthorized: false,
            currencyRate: null,
            redirectCheckout: false,
            requirementsHandler: this.requirementsHandler,
            ApiCurrency: getSymbolFromCurrency('RMB'),
            ConversionCurrency: getSymbolFromCurrency('NGN'),

        };
        this.AddToCart = this.AddToCart.bind(this)

    }

    async componentWillMount() {
        window.scrollTo(0, 0);
        this.setState({ loader: true });
        // console.log(this.props, "componentWillMount ProductDetails")

        var search = this.props.location.search.substring(1);
        if (search) {
            this.props.onGetproduct(search).then(async (response) => {
                if (response.success === true) {
                    window.history.pushState(null, null, `/product-detail/search?${search.trim()}`);
                    let product_item = response?.data?.item;
                    console.log(product_item, "product_itemproduct_itemproduct_itemproduct_itemproduct_item")
                    let product_data = {};
                    product_data.title = product_item?.title;

                    product_data.external_id = product_item?.num_iid;
                    product_data.external_url = product_item?.detail_url;

                    product_data.product_primary_image = product_item?.pic_url;
                    product_data.service_charges = product_item?.charge ? product_item?.charge : 0;
                    product_data.orginal_price = product_item?.orginal_price ? product_item?.orginal_price : 0;
                    product_data.price = product_item?.price ? product_item?.price : product_item?.orginal_price;
                    product_data.quantity = this.state.quantity;
                    product_data.skus = product_item.skus;
                    if (product_item?.item_imgs) {
                        let product_images = [];
                        for (let item_img of product_item?.item_imgs) {
                            if (item_img?.url) {
                                product_images.push(item_img?.url);
                            }
                        }
                        product_data.product_images = product_images;
                    }

                    var product_requirements = {};
                    if (product_item?.props_list != null) {
                        for (var key in product_item.props_list) {
                            var propsListNewKey = product_item.props_list[key].split(":")[0];
                            var propsListKeyValue = product_item.props_list[key].split(":")[1];

                            // if (!(propsListNewKey in product_requirements)){
                            if (product_requirements[propsListNewKey] != null) {
                                if (!(key in product_item.props_img)) {
                                    product_requirements[propsListNewKey].push({ properties: key, value: propsListKeyValue.trim() });
                                } else {
                                    let uniqeImage = product_requirements[propsListNewKey].some((item) => item.url === product_item.props_img[key])
                                    if (!uniqeImage) {
                                        product_requirements[propsListNewKey].push({ properties: key, value: propsListKeyValue.trim(), url: product_item.props_img[key] });
                                    }
                                }
                            } else {
                                if (!(key in product_item.props_img)) {
                                    product_requirements[propsListNewKey] = [{ properties: key, value: propsListKeyValue.trim() }];
                                } else {
                                    product_requirements[propsListNewKey] = [{ properties: key, value: propsListKeyValue.trim(), url: product_item.props_img[key] }];
                                }
                            }
                            // }

                        }
                        product_data.product_requirements = product_requirements;
                    }
                    // console.log(product_requirements, "product_requirements")
                    this.setState({
                        loader: false,
                        product_detail: product_data,
                        currencyRate: response.currencyRate,
                        like: response.wishlistLike
                    });

                } else {
                    this.setState({ loader: false });
                    window.history.back();
                    setTimeout(() => {
                        toast.error("Product Detail not found", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }, 1000);
                }
            });
        } else {
            toast.error("Please add some text or link to search items", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    DecreaseItem = () => {
        this.setState((prevState) => {
            if (prevState.quantity > 1) {
                return {
                    quantity: prevState.quantity - 1,
                };
            }
        });
    };

    IncrementItem = () => {
        this.setState((prevState) => {
            if (prevState.quantity < 9) {
                return {
                    quantity: prevState.quantity + 1,
                };
            }
        });
    };

    // AddToCart = (event, item) => {
    //     if (!this.state.valid || this.state.quantity === 0) {
    //         if (!this.state.valid) {
    //             toast.error("Please Select Product Requirements Properly", {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         } else {
    //             toast.error("Please ", {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         }
    //     } else {
    //         const form_data = new FormData();
    //         form_data.append("quantity", this.state.quantity);
    //         let product_detail = this.state.product_detail;
    //         product_detail.quantity = this.state.quantity;
    //         form_data.append("product_detail", JSON.stringify(product_detail));
    //         form_data.append("product_requirements", this.state.product_sku_requirements);
    //         if(item === "buy_now"){
    //             form_data.append("clear_cart", "1");
    //         }

    //         this.props.onAddCart(form_data).then((response) => {
    //             if (response.success === true) {
    //                 if(item === "buy_now"){
    //                     this.setState({ redirectCheckout: true });
    //                     return true;
    //                 }
    //                 this.props.onCart().then((response) => {
    //                     this.setState({
    //                         cartItemCount: response.data?.length,
    //                     })
    //                 })
    //                 toast.success(response.message, {
    //                     position: toast.POSITION.TOP_RIGHT,
    //                 });
    //             } else {
    //                 if (response.message == "Unauthorized") {
    //                     toast.error("You still not login, please login to save item to your shopping cart", {
    //                         position: toast.POSITION.TOP_RIGHT,
    //                     });
    //                     setTimeout(function () {
    //                         this.setState({
    //                             Unauthorized: true,
    //                         })
    //                     }.bind(this), 6000);
    //                 } else {
    //                     toast.error(response.message, {
    //                         position: toast.POSITION.TOP_RIGHT,
    //                     });
    //                 }
    //             }
    //         });
    //     }

    // };

    AddToCart = (event, item) => {
        if (!this.state.valid || this.state.quantity === 0) {
            if (!this.state.valid) {
                toast.error("Please Select Product Requirements Properly", {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error("Please enter a valid quantity", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            const form_data = new FormData();
            form_data.append("quantity", this.state.quantity);
            let product_detail = this.state.product_detail;
            product_detail.quantity = this.state.quantity;
            form_data.append("product_detail", JSON.stringify(product_detail));
            form_data.append("product_requirements", this.state.product_sku_requirements);
            if (item === "buy_now") {
                form_data.append("clear_cart", "1");
            }
    
            this.props.onAddCart(form_data)
                .then((response) => {
                    if (response.success === true) {
                        if (item === "buy_now") {
                            this.setState({ redirectCheckout: true });
                            return true;
                        }
                        this.props.onCart().then((response) => {
                            this.setState({
                                cartItemCount: response.data?.length,
                            });
                        });
                        toast.success(response.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        if (response.message === "Unauthorized") {
                            toast.error("You are not logged in. Please log in to save items to your shopping cart", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            setTimeout(() => {
                                this.setState({
                                    Unauthorized: true,
                                });
                            }, 6000);
                        } else {
                            toast.error(response.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    }
                })
                .catch((error) => {
                    // Handle errors from the API call, if any
                    console.error("Error adding to cart:", error);
                    toast.error("An error occurred while adding the item to your cart", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
    };
    
    AddToWishlist = (event) => {
        if (!this.state.valid || this.state.quantity === 0) {
            if (!this.state.valid) {
                toast.error("Please Select Product Requirements Properly", {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error("Please ", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            const form_data = new FormData();
            form_data.append("quantity", this.state.quantity);
            let product_detail = this.state.product_detail;
            product_detail.quantity = this.state.quantity;
            form_data.append("product_detail", JSON.stringify(product_detail));
            form_data.append("product_requirements", this.state.product_sku_requirements);
            this.props.onWishList(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.setState({
                        like: true,
                    });
                } else {
                    if (response.message == "Unauthorized") {
                        toast.error("You still not login, please login to save item to your shopping cart", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTimeout(function () {
                            this.setState({
                                Unauthorized: true,
                            })
                        }.bind(this), 6000);
                    } else {
                        this.setState({ loader: false });
                        toast.error("No results found with this text", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                }
            });
        }

    };

    requirementsHandler = (item, properties_name) => {
        var active_properties = this.state.active_properties;
        if (item?.url) {
            let product_detail = this.state.product_detail;

            product_detail.product_primary_image = item.url;
            this.setState({
                product_detail: product_detail,
            });
        }
        if (!Object.keys(active_properties)?.length === 0) {
            Object.keys(active_properties).forEach((item) => {
                if (item === properties_name) {

                    active_properties[item] = item.properties
                } else {
                    active_properties[properties_name] = item.properties
                }
            })
        } else {
            active_properties[properties_name] = item.properties
        }

        var allProperties = Object.values(active_properties);
        this.state.product_detail.skus.sku.map((item) => {

            let properties = item.properties.split(";");
            let difference = properties.filter(x => !allProperties.includes(x));
            if (difference?.length === 0) {
                // console.log(difference, "incudes");
                this.setState(prevState => ({
                    product_detail: {
                        ...prevState.product_detail,
                        orginal_price: item.orginal_price,
                        price: item.price,
                    }
                }))
                this.setState({ product_sku_requirements: item.properties_name });
            }
        });

        this.setState({ active_properties: active_properties });

        var requirements_validation = this.state.requirements_validation;
        requirements_validation[properties_name] = true;

        for (let key in this.state.product_detail.product_requirements) {
            if (!(key in requirements_validation)) {
                this.setState({ valid: false });
            } else {
                this.setState({ valid: true });
            }
        }

        this.setState({ requirements_validation: requirements_validation });
    }

    GetProduct = (product_search) => {
        if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
            this.LoginHeaderEvent.LoginHeaderSearchLoaderStart();
        }
        if (product_search) {
            this.props.onGetproduct(product_search).then(async (response) => {
                if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
                    this.LoginHeaderEvent.LoginHeaderSearchLoaderStop();
                }
                if (response.success === true) {
                    window.scrollTo(0, 0);
                    if (!response.data?.item) {
                        this.setState({
                            is_redirect: true,
                            redirect_url: `/product-list/search?${product_search}`,
                            redirect_state: {
                                search_input: product_search,
                                product_details: response.data,
                            },
                           
                        }); 
        
                    } else {
                        window.history.pushState(null, null, `/product-detail/search?${product_search.trim()}`);
                        let product_item = response?.data?.item;

                        let product_data = {};
                        product_data.title = product_item?.title;

                        product_data.external_id = product_item?.num_iid;
                        product_data.external_url = product_item?.detail_url;

                        product_data.product_primary_image = product_item?.pic_url;
                        product_data.service_charges = product_item?.charge ? product_item?.charge : 0;
                        product_data.orginal_price = product_item?.orginal_price ? product_item?.orginal_price : 0;
                        product_data.price = product_item?.price ? product_item?.price : product_item?.orginal_price;
                        product_data.quantity = this.state.quantity;
                        product_data.skus = product_item?.skus;
                        if (product_item?.item_imgs) {
                            let product_images = [];
                            for (let item_img of product_item?.item_imgs) {
                                if (item_img?.url) {
                                    product_images.push(item_img?.url);
                                }
                            }
                            product_data.product_images = product_images;
                        }

                        var product_requirements = {};
                        if (product_item?.props_list != null) {
                            for (var key in product_item.props_list) {
                                var propsListNewKey = product_item.props_list[key].split(":")[0];
                                var propsListKeyValue = product_item.props_list[key].split(":")[1];
                                if (product_requirements[propsListNewKey] != null) {
                                    if (!(key in product_item.props_img)) {
                                        product_requirements[propsListNewKey].push({ properties: key, value: propsListKeyValue.trim() });
                                    } else {
                                        product_requirements[propsListNewKey].push({ properties: key, value: propsListKeyValue.trim(), url: product_item.props_img[key] });
                                    }
                                } else {
                                    if (!(key in product_item.props_img)) {
                                        product_requirements[propsListNewKey] = [{ properties: key, value: propsListKeyValue.trim() }];
                                    } else {
                                        product_requirements[propsListNewKey] = [{ properties: key, value: propsListKeyValue.trim(), url: product_item.props_img[key] }];
                                    }
                                }
                            }
                            product_data.product_requirements = product_requirements;
                        }
                        // console.log(product_data, "product_requirements")

                        this.setState({
                            loader: false,
                            product_detail: product_data,
                        });
                    }


                } else {
                    this.setState({ loader: false });
                    
                    toast.error("No results found with this text", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
        } else {
            if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
                this.LoginHeaderEvent.LoginHeaderSearchLoaderStop();
            }
            toast.error("Please add some text or link to search items", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }


    };

    render() {
        const {redirectCheckout, currencyRate, ConversionCurrency, ApiCurrency, cartItemCount, search_input, Unauthorized, quantity, product_detail, carousel_responsive, like, requirementsHandler, product_sku_requirements, active_properties, requirements_validation } = this.state;
        console.log(product_detail, "product_detail++++++++++++++++")
        const state = {
            data: 'Some data',
            value: 42,
        };
        
        return (
            <React.Fragment>
                {this.state.is_redirect ? <Navigate to={this.state.redirect_url} state={this.state.redirect_state} /> : null}
                {redirectCheckout ? <Navigate to={"/checkout"} /> : null}
                {Unauthorized ? <Navigate to={"/login"} /> : null}
                {this.state.loader ? <Loader /> : null}
                {/* {this.props.loader ? <Loader /> : null} */}
                <div className="">
                    <LoginHeader
                        page_type="product_details"
                        location={this.props?.location}
                        ref={(ref) => (this.LoginHeaderEvent = ref)}
                        header_search_loader={this.state.header_search_loader}
                        propsGetProduct={(search_input) => this.GetProduct(search_input)}
                        cartItemCount={cartItemCount}
                    />
                    <div className="product-sec">
                        <div className="conatiner-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    {product_detail?.product_primary_image && (
                                        <div className="swiper mySwiper2">
                                            <div className="swiper-wrapper">
                                                <img src={product_detail?.product_primary_image} alt="product" style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="product-slider">
                                        {product_detail?.product_images && (
                                            <div className="swiper mySwiper">
                                                <div className="swiper-wrapper">
                                                    <Carousel responsive={carousel_responsive}>
                                                        {product_detail.product_images.map((imagSrc, picture_key) => (
                                                            <div
                                                                className={imagSrc === product_detail.product_primary_image ? "active_propertie swiper-slide" : "swiper-slide"}
                                                                key={picture_key}
                                                                onClick={(event) => {
                                                                    let product_detail = this.state.product_detail;
                                                                    product_detail.product_primary_image = imagSrc;
                                                                    this.setState({
                                                                        product_detail: product_detail,
                                                                    });
                                                                }}
                                                            >
                                                                <img src={imagSrc} alt="product" />
                                                            </div>
                                                        ))}
                                                    </Carousel>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="product-detail">
                                        <h1>{product_detail?.title}</h1>
                                        <h6 className="mb-3">
                                            <del>
                                                {/* {product_detail?.orginal_price > 0 && `¥${product_detail?.orginal_price}`} */}
                                            </del>
                                            {ApiCurrency}{parseFloat(product_detail?.price).toFixed(2)}  ≈  {ConversionCurrency}{parseFloat(product_detail?.price * currencyRate).toFixed(2)}
                                        </h6>
                                        <h5 className="mb-3">Service charges: ¥ {product_detail?.service_charges}</h5>
                                        <div className="quantity-btn btn-added">
                                            <p>Qty</p>
                                            <input type="button" className="minus" value="-" onClick={(event) => this.DecreaseItem()} />
                                            <input readOnly type="number" className="input-text qty text" value={quantity} step="1" min="1" name="quantity" />
                                            <input type="button" className="plus" value="+" onClick={(event) => this.IncrementItem()} />
                                        </div>
                                        {product_detail?.product_requirements != null && (
                                            Object.keys(product_detail.product_requirements).map(function (key, index) {
                                                return (
                                                    <div
                                                        className={requirements_validation[key] ? "panel panel-default" : "panel panel-danger"}
                                                    >
                                                        <div className="panel-heading">
                                                            <h3 className="panel-title">{key}</h3>
                                                        </div>
                                                        <div className="panel-body">
                                                            {product_detail.product_requirements[key].map((item) => {
                                                                return (
                                                                    <>
                                                                        {item?.url &&
                                                                            (<img
                                                                                src={item.url}
                                                                                alt="props_img"
                                                                                className={product_detail.product_primary_image === item.url ? "active_propertie img-thumbnail" : "img-thumbnail"}
                                                                                width="50"
                                                                                onClick={() => requirementsHandler(item, key)}
                                                                            />)}
                                                                        {item?.url === undefined &&
                                                                            (<button
                                                                                type="button"
                                                                                className={active_properties[key] === item.properties ? "active_propertie btn btn-default" : "btn btn-default"}
                                                                                onClick={() => requirementsHandler(item, key)}
                                                                            >
                                                                                {item.value}
                                                                            </button>
                                                                            )}
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )}
                                        <textarea className="requirements" placeholder="Requirements..." value={product_sku_requirements}></textarea>
                                    </div>
                                    <div className="product-btn">

                                        <button className="btn btn-primary buy-now" onClick={(event) => this.AddToCart(event, "buy_now")}>
                                            <i class="fa-solid fa-bag-shopping"></i>
                                            Buy Now
                                        </button>

                                        <button className="btn btn-primary cart" onClick={(event) => this.AddToCart(event, "add_to_cart")}>
                                            <img src="/assets/images/cart-img.png" alt="cart" />
                                            Add To Cart
                                        </button>
                                        <Link></Link>
                                        <button className="btn btn-primary wish" onClick={(event) => this.AddToWishlist(event)}>
                                            <i className={like ? "fa fa-heart like" : "fa fa-heart dislike"} aria-hidden="true"></i>
                                            Add To Wishlist
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="check-product">
                        <div className="conatiner-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="check-field">
                                        <input
                                            type="text"
                                            className="check-p"
                                            value={search_input ? search_input : product_detail?.external_url}
                                            onChange={(event) => {
                                                this.setState({
                                                    search_input: event.target.value,
                                                });
                                            }}
                                        />
                                        <button className="btn btn-primary check" onClick={() => this.GetProduct(search_input ? search_input : product_detail?.external_url)}>
                                            <img src="/assets/images/check.png" alt="check" />
                                            Check Product
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginFooter />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onCart: userActionsCart.GetCart,
    onGetproduct: userActions.Getproduct,
    onAddCart: userActions.AddCart,
    onWishList: AccountActions.AddWishlist,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
