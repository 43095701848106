import { json } from "react-router-dom";

const Validation = (event, form_element) => {
    let value = form_element.value;
    let rule = form_element.validations;
    value = typeof value === "object" ? JSON.stringify(value) : value;
    value = typeof value === "boolean" ? JSON.stringify(value) : value;

    if (rule.required && form_element.type !== "options") {
        if (value == null || (typeof value === "string" && value.trim() === "") || (typeof value === "number" && value === "")) {
            return {
                valid: false,
                error_msg: `${form_element.label.replace('*','')} is required!`,
            };
        }
    }

    // If filed is email
    if (rule.email) {
        let email_regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        // let email_regexp = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        if (email_regexp.test(value) === false) {
            return {
                valid: false,
                error_msg: "Email format is incorrect.",
            };
        }
    }

    // If filed is phone
    if (rule.phone_no) {
        let number_regexp = /^[0-9-+]*$/;
        if (number_regexp.test(value) === false) {
            return {
                valid: false,
                error_msg: "Phone No. format is incorrect.",
            };
        }
    }

    // Password Validation
    if (rule.password_validation) {
        let password_regexp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$/;
        if (!value.match(password_regexp)) {
            return {
                valid: false,
                error_msg: "Password should be at least 8 characters and include a number and special character",
            };
        }
    }

    // Password Validation
    if (rule.terms) {
        if (!form_element.isChecked) {
            return {
                valid: false,
                error_msg: "",
            };
        }
    }


    // Validate min length
    if (rule.min_length) {
        if (value.length < rule.min_length) {
            return {
                valid: false,
                error_msg: "Please enter the correct format",
            };
        }
    }

    // Validate Max length
    if (rule.max_length) {
        if (value.length > rule.max_length) {
            return {
                valid: false,
                error_msg: "Please enter the correct format",
            };
        }
    }

    if (!rule.required) {
        return {
            valid: true,
            error_msg: "",
        };
    }

    return {
        valid: true,
        error_msg: "",
    };
};

export default Validation;
