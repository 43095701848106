import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import LoginHeader from "../../../components/LoginHeader";
import LoginFooter from "../../../components/LoginFooter";
import Loader from "../../../components/Utility/Loader";
import ReactPaginate from 'react-paginate';
import getSymbolFromCurrency from 'currency-symbol-map';

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zero_product: false,
            currentPage: 1,
            product_details: [],
            header_search_loader: false,
            ApiCurrency: getSymbolFromCurrency('RMB'),
            ConversionCurrency: getSymbolFromCurrency('NGN'),
            currencyRate: null,
        };
    }

    componentWillMount() {
        if(this.props.location?.state?.product_details.length > 0) {
            this.setState({
                product_details: this.props.location.state.product_details,
                currencyRate: this.props.location.state.currencyRate,
                });
        }else{
            this.setState({ loader: true });
            // console.log(this.props, "componentWillMount ProductList")
            window.scrollTo(0, 0);
            var search = this.props.location.search.substring(1);
            // console.log(search, this.props.location)
            if(search){
                this.props.onGetproduct(search).then((response) => {
                    if (response.success === true) {
                        this.setState({ loader: false });
                        window.history.pushState(null, null, `/product-list/search?${search.trim()}`);
                        let product_details = [];
                        if (response.data.length > 0 || response.data?.item) {
                            product_details = response.data;
                        }else{
                            this.setState({ zero_product: true });
                        }
                        this.setState({
                             zero_product: false,
                             product_details: product_details,
                             currencyRate: response.currencyRate
                             });
                    } else {
                        this.setState({ loader: false });
                        toast.error("No results found with this text", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                });
            }else{
                toast.error("Please add some text or link to search items", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
           
        }


    }

    GetProducts = (product_search) => {
        if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
            this.LoginHeaderEvent.LoginHeaderSearchLoaderStart();
        }

        if(product_search){
            this.props.onGetproduct(product_search).then((response) => {
                // console.log(response, "++++++++++++++++response product list++++++++++++++++++++++++++++")
                if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
                    this.LoginHeaderEvent.LoginHeaderSearchLoaderStop();
                }
                if (response.success === true) {
                    if (response.data?.item) {
                        this.setState({
                            is_redirect: true,
                            redirect_url: `/product-detail/search?${product_search}`,
                            redirect_state: {
                                search_input: product_search,
                                product_item: response.data?.item,
                            },
                        })
                    }else{
                        window.history.pushState(null, null, `/product-list/search?${product_search.trim()}`);
                        let product_details = [];
                        if (response.data.length > 0 || response.data?.item) {
                            product_details = response.data;
                            this.setState({
                                zero_product: false,
                                product_details: product_details,
                                currencyRate: response.currencyRate
                           });
                        }else{
                            this.setState({ zero_product: true });
                        }
                        
                    }
    
                } else {
                    toast.error("No results found with this text", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
        }else{
            if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
                this.LoginHeaderEvent.LoginHeaderSearchLoaderStop();
            }
            toast.error("Please add some text or link to search items", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
       
    };

    handlePageChange = (event) => {
        const newOffset = (event.selected * 15) % this.state.product_details.length;
        this.setState({ currentPage: newOffset });
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    render() {
        const { product_details, currentPage, zero_product, ApiCurrency, currencyRate, ConversionCurrency } = this.state;
        
        const itemsPerPage = 15;
        const endIndex = currentPage + itemsPerPage;
        const products = product_details.filter(product => product.TaobaoItemUrl !== "");
        const currentPageData = products.slice(currentPage, endIndex);
        const totalPages = Math.ceil(products.length / itemsPerPage);
        // console.log("++++++currentPageData+++++++++++==++=+====dwpk__", currentPageData);
        return (
            <React.Fragment>
            {this.state.is_redirect ? <Navigate to={this.state.redirect_url} state={this.state.redirect_state} /> : null}
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                <div className="">
                    <LoginHeader
                        page_type="product_list"
                        location={this.props?.location}
                        ref={(ref) => (this.LoginHeaderEvent = ref)}
                        header_search_loader={this.state.header_search_loader}
                        propsGetProduct={(search_input) => this.GetProducts(search_input)}
                        product={product_details}
                    />

                    <div className="category-sec">x``
                        <div className="container-fluid">
                            <div className="row" id="currentPageData">
                                    {zero_product && "NO RESULT FOUND PLEASE TRY AGAIN WITH OTHER KEYWORDS"}
                                    {currentPageData.map((item, product_key) => 
                                        (
                                            <div className="col-lg-4 col-md-6" key={product_key}>
                                            <div className="product-box">
                                                <div id="div1">
                                                    <section className="section-grid">
                                                        <div className="grid-prod">
                                                            <div className="prod-grid">
                                                                <Link to={`/product-detail/search?${item?.TaobaoItemUrl}`} state={{ product_detail: item }} className="link">
                                                                    <img src={item?.MainPictureUrl} alt="lower" width="400" height="400" />
                                                                    <h4>{item?.Title}</h4>
                                                                    <section className="product__price">{ApiCurrency}{parseFloat(item?.Price?.OriginalPrice).toFixed(2)} ≈ {ConversionCurrency}{parseFloat(item?.Price?.OriginalPrice * currencyRate).toFixed(2)} </section>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    )}
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={this.handlePageChange}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={totalPages}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                            </div>
                        </div>
                    </div>

                    <LoginFooter />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onGetproduct: userActions.Getproduct,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
