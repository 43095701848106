import React from "react";
import { Routes, Route } from "react-router-dom";

/*** Page imports ***/
import HowToBuy from "./containers/Page/HowToBuy";
import PrivacyPolicy from "./containers/Page/PrivacyPolicy";
import AboutUs from "./containers/Page/AboutUs";
import Terms from "./containers/Page/Terms";
import Faq from "./containers/Page/Faq";
import ContactUs from "./containers/Page/ContactUs";

/*** Application imports ***/
import Logout from "./containers/Auth/logout";
import Signin from "./containers/Auth/signin";
import Signup from "./containers/Auth/signup";
import ResetPassword from "./containers/Auth/ResetPassword";

/**** Admin import */
import AdminSignin from "./containers/Auth/Admin/signin";
import AdminSignup from "./containers/Auth/Admin/signup";
import CurrencyConverter from "./containers/Page/AdminPages/Currencyconverter";

import Home from "./containers/Application/Home";
import Account from "./containers/Application/Account";
import Product from "./containers/Application/Product";

import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";
import AdminProtectRoute from "./AdminProtectRoute";
import SuperAdminProtectRoute from "./SuperAdminProtectRoute";
import VerifyEmail from './containers/Auth/VerifyEmail';

/**Super Admin Routes**/
import SuperAdminAccount from "./containers/Auth/SuperAdmin/Account";


const Router = (props) => (
    <Routes>
        {/***************** Page Router *****************/}
        <Route exact path="/how-to-buy" element={<HowToBuy />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/terms-condition" element={<Terms />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/contact-us" element={<ContactUs />} />

        <Route exact path="/logout" element={<Logout />} />
        <Route path="/reset-password/:useremail" element={<ResetPassword />} />

        {/*****************Auth Router *****************/}
        <Route exact path="/login" element={<AuthRoute><Signin /></AuthRoute>} />
        <Route exact path="/sign-up" element={<AuthRoute><Signup /></AuthRoute>} />

        {/***************** admin Router *****************/}
        <Route exact path="/admin-login" element={<AdminProtectRoute><AdminSignin /></AdminProtectRoute>} />
        <Route exact path="/admin-sign-up" element={<AdminProtectRoute page_type={"signup"} ><AdminSignup /></AdminProtectRoute>} />
        <Route exact path="/currency-rate" element={<CurrencyConverter />} />

        {/***************** Application Router *****************/}
        <Route exact path="/" element={<ProtectedRoute ><Home /></ProtectedRoute>} />
        <Route exact path="/cart" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route exact path="/wishlist" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route exact path="/checkout" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route exact path="/account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route exact path="/completed-orders" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route exact path="/view-order-details" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route exact path="/in-progress-orders" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route exact path="/complaints" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route exact path="/product-list/:search" element={<Product />} />
        <Route exact path="/product-categories/:categories" element={<Product />} />
        <Route exact path="/product-detail/:search" element={<Product />} />
        <Route exact path="/verify/:token" element={<VerifyEmail />} />

        {/******************************Super Admin Routes***********************************/}
        <Route exact path="/super-admin-login" element={<SuperAdminAccount />} />
        <Route exact path="/super-admin-dashboard" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />
        <Route exact path="/super-admin-manage-customer" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />
        <Route exact path="/super-admin-order-list" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />
        <Route exact path="/super-admin-complaints" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />
        <Route exact path="/super-admin-add-user" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />
        <Route exact path="/super-admin-view-user/:id" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />
        <Route exact path="/super-admin-edit-user/:id" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />
        <Route exact path="/super-admin-view-order/:id" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />
        <Route exact path="super-admin-view-complaint/:id" element={<SuperAdminProtectRoute><SuperAdminAccount /></SuperAdminProtectRoute>} />

    </Routes>
);

export default Router;
