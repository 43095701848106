import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import * as actionType from "../../constants/actionTypes";
import axios from "axios";

function VerifyEmail() {
  const { token } = useParams(); // Get the verification token from the URL parameter
  const [verificationResult, setVerificationResult] = useState(null);

  useEffect(() => {
    // Call your API to verify the email using the verification token
    // Set the result of the verification to the state variable
   const GetCart = (token) => {
            return axios({
                method: "post",
                data: {email: token},
                url: `${actionType.environment_url}/api/verify-email`,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
    } 

    GetCart(token).then(result => {
        console.log(result.data.success)
      setVerificationResult(result.data.success);
    });
  }, [token]);

  return (
    <div>
      {verificationResult === null && <p>Verifying email...</p>}
      {verificationResult === true &&  <Navigate to="/login" />}
      {verificationResult === false && <p>Failed to verify email.</p>}
    </div>
  );
}

export default VerifyEmail;
