import React from "react";
import { Link } from "react-router-dom";

import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";

class HowToBuy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            displayTab: 'hometab'
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const { displayTab } = this.state;
        return (
            <React.Fragment>
                <div className="">
                    <LoginHeader />
                    <div className="top-content buy-bg">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="main-heading">
                                        <h1>How To Buy</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buy-sec">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Steps to Buy Products</h2>
                                    <div className="tab-sec">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li
                                                className={displayTab === 'hometab' ? 'active' : ''}
                                                onClick={() => { this.setState({ displayTab: "hometab" }) }}
                                            >
                                                <Link to="#">
                                                    Step 1
                                                </Link>
                                            </li>
                                            <li
                                                className={displayTab === 'javatab' ? 'active' : ''}
                                                onClick={() => { this.setState({ displayTab: "javatab" }) }}
                                            >
                                                <Link to="#">
                                                    Step 2
                                                </Link>
                                            </li>
                                            <li
                                                className={displayTab === 'csharptab' ? 'active' : ''}
                                                onClick={() => { this.setState({ displayTab: "csharptab" }) }}
                                            >
                                                <Link to="#">
                                                    Step 3
                                                </Link>
                                            </li>
                                            <li
                                                className={displayTab === 'mysqltab' ? 'active' : ''}
                                                onClick={() => { this.setState({ displayTab: "mysqltab" }) }}
                                            >
                                                <Link to="#">
                                                    Video Demo
                                                </Link>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            {displayTab === 'hometab' &&
                                                <div className="tab-pane active" id="hometab">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-6">
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                                            <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <img src="/assets/images/about-img.png" alt="about" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {displayTab === 'javatab' &&
                                                <div className="tab-pane active" id="javatab">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-6">
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                                            <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <img src="/assets/images/about-img.png" alt="about" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {displayTab === 'csharptab' &&
                                                <div className="tab-pane active" id="csharptab">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-6">
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                                            <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <img src="/assets/images/about-img.png" alt="about" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {displayTab === 'mysqltab' &&
                                                <div className="tab-pane active" id="mysqltab">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-6">
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                                            <span>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <img src="/assets/images/about-img.png" alt="about" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginFooter />
                </div>
            </React.Fragment>
        );
    }
}

export default HowToBuy;
