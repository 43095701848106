import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
    LoginSocialFacebook,
    LoginSocialInstagram,
    LoginSocialGoogle,
    IResolveParams
} from "reactjs-social-login";

import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader";
import Validations from "../../../components/Utility/Validations";

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            register_form: {
                full_name: {
                    type: "text",
                    label: "Full name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Full name",
                    validations: { required: true },
                },
                email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter your email",
                    validations: { required: true, email: true },
                },
                phone_no: {
                    type: "text",
                    label: "Phone number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Phone Number",
                    validations: { required: true, phone_no: true },
                },
                password: {
                    type: "password",
                    label: "Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter your password",
                    validations: { required: true },
                },
            },    
            terms: false, 
            eye : 'fa fa-eye-slash'       
        };
    }

    inputChangeHandler(event, identifier) {
        const update_register_form = { ...this.state.register_form };
        const form_element = { ...update_register_form[identifier] };
        form_element.value = event.target.value;
        if(identifier === "terms"){
            if(event.target.checked){
                form_element.isChecked = true;
            }else{
                form_element.isChecked = false;
            }
            
        }
        // Check Validation
        let validate_element = Validations(event, form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_register_form[identifier] = form_element;
        this.setState({ register_form: update_register_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_register_form = this.state.register_form;
        for (let key in update_register_form) {
            let form_element = update_register_form[key];
            let validate_element = Validations(event, form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_register_form[key] = form_element;
        }
        this.setState({ register_form: update_register_form });

        if (is_form_valid) {
            const form_data = new FormData();
            form_data.append("user_type", "admin");
            for (let key in this.state.register_form) {
                form_data.append(key, this.state.register_form[key].value);
            }

            this.props.onSignUp(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/currency-rate",
                        });
                    }, 1000);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_LEFT,
                    });
                }
            });
        }
    };

    handleSocialLogin = (provider, user) => {
        const form_data = new FormData();
        form_data.append("provider", provider);
        form_data.append("user", JSON.stringify(user));

        this.props.onSocialSignUp(form_data).then((response) => {
            if (response.success === true) {
                this.props.onGetAuthUser().then((response) => {
                    if (response.success === true) {
                        toast.success(response.message, { position: toast.POSITION.TOP_LEFT });
                        setTimeout(() => {
                            this.setState({ redirect_url: "/", isAuthenticated: true });
                        }, 500);
                    } else {
                        toast.error(response.message, { position: toast.POSITION.TOP_LEFT });
                    }
                });
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_LEFT });
            }
        });
    };

    handleClickEye = () => {
        if(this.state.register_form.password.type === 'password'){
            this.setState(prevState => ({
                register_form : {
                    ...prevState.register_form,
                    password : {
                        ...prevState.register_form.password,
                        type: 'text',
                    }
                },
                eye : 'fa fa-eye'
    
            }))
        }else{
            this.setState(prevState => ({
                register_form : {
                    ...prevState.register_form,
                    password : {
                        ...prevState.register_form.password,
                        type: 'password',
                    }
                },
                eye : 'fa fa-eye-slash'
    
            }))
        }

    }

    render() {
        const { register_form, terms, eye } = this.state;
        // console.log(process.env)
        return (
            <React.Fragment>
                <ToastContainer />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div className="">
                    <div className="login-sec">
                        <div className="conatiner-fluid">
                            <div className="row">
                                <div className="login-form">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="field-sec">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <h3 className="sing_in">Sign Up</h3>
                                                        <div className="form-group">
                                                            <input
                                                                type={register_form.full_name.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={register_form.full_name.placeholder}
                                                                value={register_form.full_name.value ? register_form.full_name.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "full_name")}
                                                                onBlur={(event) => {
                                                                    register_form.full_name.onBlur_out = true;
                                                                    this.setState({
                                                                        register_form: register_form,
                                                                    });
                                                                }}
                                                            />
                                                            {!register_form.full_name.valid && register_form.full_name.onBlur_out && <div className="error field-error">{register_form.full_name.error_msg}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type={register_form.email.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={register_form.email.placeholder}
                                                                value={register_form.email.value ? register_form.email.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                onBlur={(event) => {
                                                                    register_form.email.onBlur_out = true;
                                                                    this.setState({
                                                                        register_form: register_form,
                                                                    });
                                                                }}
                                                            />
                                                            {!register_form.email.valid && register_form.email.onBlur_out && <div className="error field-error">{register_form.email.error_msg}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                id="id_password"
                                                                type={register_form.password.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={register_form.password.placeholder}
                                                                value={register_form.password.value ? register_form.password.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "password")}
                                                                onBlur={(event) => {
                                                                    register_form.password.onBlur_out = true;
                                                                    this.setState({
                                                                        register_form: register_form,
                                                                    });
                                                                }}
                                                            />
                                                            <i className={eye}
                                                               id="togglePassword"
                                                               onClick={()=> this.handleClickEye()}>
                                                               </i>
                                                            {!register_form.password.valid && register_form.password.onBlur_out && <div className="error field-error">{register_form.password.error_msg}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type={register_form.phone_no.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={register_form.phone_no.placeholder}
                                                                value={register_form.phone_no.value ? register_form.phone_no.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "phone_no")}
                                                                onBlur={(event) => {
                                                                    register_form.phone_no.onBlur_out = true;
                                                                    this.setState({
                                                                        register_form: register_form,
                                                                    });
                                                                }}
                                                            />
                                                            {!register_form.phone_no.valid && register_form.phone_no.onBlur_out && <div className="error field-error">{register_form.phone_no.error_msg}</div>}
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="invalid-feedback"></div>
                                                        </div>
                                                        
                                                        <button type="submit" className="btn btn-primary sign-in">
                                                            Sign Up
                                                        </button>
                                                        <p className="or">OR</p>
                                                    </form>
                                                    <div className="social-icon">
                                                        <ul>
                                                            <li>
                                                                <LoginSocialFacebook
                                                                    appId={process.env.REACT_APP_FACEBOOK}
                                                                    fieldsProfile={"first_name,last_name,name,email"}
                                                                    onResolve={({ provider, data }: IResolveParams) => this.handleSocialLogin(provider, data)}
                                                                    onReject={(error) => toast.error(error.message, { position: toast.POSITION.TOP_LEFT })}
                                                                >
                                                                    <img src="/assets/images/Facebook.svg" alt="facebook" />
                                                                </LoginSocialFacebook>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <img src="/assets/images/Twitter.svg" alt="twitter" />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <LoginSocialInstagram
                                                                    client_id={process.env.REACT_APP_INSTAGRAM_APP_ID}
                                                                    client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET}
                                                                    scope={"user_profile"}
                                                                    redirect_uri={`${process.env.REACT_APP_URL}/sign-up`}
                                                                    onResolve={({ provider, data }: IResolveParams) => this.handleSocialLogin(provider, data)}
                                                                    onReject={(error) => toast.error(error.message, { position: toast.POSITION.TOP_LEFT })}
                                                                >
                                                                    <img src="/assets/images/Instagram.svg" alt="insta" />
                                                                </LoginSocialInstagram>
                                                            </li>
                                                            <li>
                                                                <LoginSocialGoogle
                                                                    client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                                    scope="https://www.googleapis.com/auth/userinfo.profile"
                                                                    onResolve={({ provider, data }: IResolveParams) => this.handleSocialLogin(provider, data)}
                                                                    onReject={(error) => toast.error(error.message, { position: toast.POSITION.TOP_LEFT })}
                                                                >
                                                                    <img src="/assets/images/Google.svg" alt="google" />
                                                                </LoginSocialGoogle>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <p>
                                                        Do you already have an account?&nbsp;<Link to="/admin-login">Sign In</Link>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="img-sec">
                                                    <img src="/assets/images/sign-in.png" alt="login" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onSignUp: userActions.SignUp,
    onGetAuthUser: userActions.GetAuthUser,
    onSocialSignUp: userActions.SocialSignUp,
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);