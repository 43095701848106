import * as Master_actionType from "../constants/MasteractionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    // console.log(action, "action product")
    switch (action.type) {
        case Master_actionType.Getproduct_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.Getproduct_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                productData: action.productData,
                token: action.token,
            };
        case Master_actionType.Getproduct_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reducer;
