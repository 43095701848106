// Product Masteractions  ---------------

export const Getproduct_START = "Getproduct_START";
export const Getproduct_SUCCESS = "Getproduct_SUCCESS";
export const Getproduct_FAIL = "Getproduct_FAIL";

// AddCart Masteractions  ---------------

export const AddCart_START = "AddCart_START";
export const AddCart_SUCCESS = "AddCart_SUCCESS";
export const AddCart_FAIL = "AddCart_FAIL";

export const GetCart_START = "GetCart_START";
export const GetCart_SUCCESS = "GetCart_SUCCESS";
export const GetCart_FAIL = "GetCart_FAIL";

export const QtyUp_START = "QtyUp_START";
export const QtyUp_SUCCESS = "QtyUp_SUCCESS";
export const QtyUp_FAIL = "QtyUp_FAIL";


// WishList Masteractions  ---------------

export const AddWishlist_START = "Add_Wishlist_START";
export const GetWishlist_START = "GET_Wishlist_START";
export const GetWishlist_SUCCESS = "Get_Wishlist_SUCCESS";
export const GetWishlist_FAIL = "Get_Wishlist_FAIL";
export const Wishlist_SUCCESS = "Get_Wishlist_SUCCESS";
export const Wishlist_FAIL = "Get_Wishlist_FAIL";

// Checkout Masteractions  ---------------

export const Checkout_START = "Checkout_START";
export const Checkout_SUCCESS = "Checkout_SUCCESS";
export const Checkout_FAIL = "Checkout_FAIL";

// Currency Rate   ---------------

export const CURRENCY_RATE_START = "CURRENCY_RATE_START";
export const CURRENCY_RATE_SUCCESS = "CURRENCY_RATE_SUCCESS";
export const CURRENCY_RATE_FAIL = "CURRENCY_RATE_FAIL";

export const GET_CURRENCY_RATE_START = "GET_CURRENCY_RATE_START";
export const GET_CURRENCY_RATE_SUCCESS = "GET_CURRENCY_RATE_SUCCESS";
export const GET_CURRENCY_RATE_FAIL = "GET_CURRENCY_RATE_FAIL";