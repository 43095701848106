import axios from "axios";
import * as actionType from "../constants/orderActionType.js";


//**Comepleted Orders */
export const GetCompleteOrderStart = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.GET_COMPLETE_ORDERS_START,
    };
};

export const GetCompleteOrderSuccess = (response) => {
    return {
        error: null,
        loader: false,
        type: actionType.GET_COMPLETE_ORDERS_SUCCESS,
        response:response
    };
};

export const  GetCompleteOrderFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.GET_COMPLETE_ORDERS_FAIL,
    };
};

//**In Progress Orders */
export const GetInProgressOrderStart = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.GET_INPROGRESS_ORDERS_START,
    };
};

export const GetInProgressOrderSuccess = (response) => {
    return {
        error: null,
        loader: false,
        type: actionType.GET_INPROGRESS_ORDERS_SUCCESS,
        response:response
    };
};

export const  GetInProgressOrderFail = (error) => {
    return {
        error: error,
        loader: false,
        type: actionType.GET_INPROGRESS_ORDERS_FAIL,
    };
};

/** Apis  */
export const GetCompletedOrders = (form_data) => {
    return (dispatch) => {
        dispatch(GetCompleteOrderStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/get-completed-orders`,
            headers: {
                // "Content-Type": "multipart/form-data",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
        .then(function (response) {
            dispatch(GetCompleteOrderSuccess(response));
            return response.data;
        })
        .catch(function (error) {
            dispatch(GetCompleteOrderFail("Something went wrong, Please try again."));
            return error;
        });
    };
};

export const GetInProgressOrders = (form_data) => {
    return (dispatch) => {
        dispatch(GetInProgressOrderStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/in-progress-orders`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
        .then(function (response) {
            dispatch(GetInProgressOrderSuccess(response));
            return response.data;
        })
        .catch(function (error) {
            dispatch(GetInProgressOrderFail("Something went wrong, Please try again."));
            return error;
        });
    };
};
