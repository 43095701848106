export const SignIn_START = "SignIn_START";
export const SignIn_SUCCESS = "SignIn_SUCCESS";
export const SignIn_FAIL = "SignIn_FAIL";

export const SocialSignIn_START = "SocialSignIn_START";
export const SocialSignIn_SUCCESS = "SocialSignIn_SUCCESS";
export const SocialSignIn_FAIL = "SocialSignIn_FAIL";

export const GetAuthUser_START = "GetAuthUser_START";
export const GetAuthUser_SUCCESS = "GetAuthUser_SUCCESS";
export const GetAuthUser_FAIL = "GetAuthUser_FAIL";

export const SignUp_START = "SignUp_START";
export const SignUp_SUCCESS = "SignUp_SUCCESS";
export const SignUp_FAIL = "SignUp_FAIL";

export const SocialSignUp_START = "SocialSignUp_START";
export const SocialSignUp_SUCCESS = "SocialSignUp_SUCCESS";
export const SocialSignUp_FAIL = "SocialSignUp_FAIL";

export const ForgotPassword_START = "ForgotPassword_START";
export const ForgotPassword_SUCCESS = "ForgotPassword_SUCCESS";
export const ForgotPassword_FAIL = "ForgotPassword_FAIL";

export const ResetPassword_START = "ResetPassword_START";
export const ResetPassword_SUCCESS = "ResetPassword_SUCCESS";
export const ResetPassword_FAIL = "ResetPassword_FAIL";

export const siteurl = process.env.REACT_APP_API_URL;
export const environment_url = process.env.REACT_APP_ENVIRONMENT_URL;

export const Address_START = "Address_START";
export const Address_SUCCESS = "Address_SUCCESS";
export const Address_FAIL = "Address_FAIL";