import React from "react";
import { Modal } from "react-bootstrap";

class ForgotPasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forgotpassword_form: {
                email: {
                    label: "Email address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
            },
            updateStatus_modal: false,
            inputFieldInvalid: false
        };
    }

    inputChangeHandler(event, identifier) {
        event.preventDefault();

        // const update_forgotpassword_form = { ...this.state.forgotpassword_form };
        // const form_element = { ...update_forgotpassword_form[identifier] };
        // form_element.value = event.target.value;

        // // Check Validation
        // let validate_element = Validations(event, form_element);
        // form_element.valid = validate_element.valid;
        // form_element.error_msg = validate_element.error_msg;

        // update_forgotpassword_form[identifier] = form_element;
        // let is_form_valid = true;
        // for (let identifier in update_forgotpassword_form) {
        //     is_form_valid = update_forgotpassword_form[identifier].valid && is_form_valid;
        // }

        // this.setState({
        //     forgotpassword_form: update_forgotpassword_form,
        // });
    }

    render() {
        const { forgotpassword_form } = this.state;
        return (
            <React.Fragment>
                 <Modal
                    backdrop="static"
                    keyboard={false}
                    className="updateStatus_modal"
                    show={this.props.orderStatus_modal}
                    onHide={() => {
                        this.props.updateStatus_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Order status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12 fv-row mb-4 mt-8 ">
                                <p> Update the order status, so that customer can track the order </p>
                                <form onSubmit={this.props.SubmitHandler} autoComplete="off">
                                    <div className={"fv-row forget-email"}>
                                        <label htmlFor="status Type" className="">Status</label>
                                            <select defaultValue={'location'} className="form-select" name="status">
                                                <option value="location">Location</option>
                                                <option value="delivered">Delivered</option>
                                            </select>
                                        {/* {!forgotpassword_form.email.valid && forgotpassword_form.email.onBlur_out && <div className="error field-error">{forgotpassword_form.email.error_msg}</div>} */}
                                    </div>
                                    <div className={"fv-row forget-email"}>
                                        <label htmlFor="location">Location</label>
                                           <input type="text" name="location" className="form-control" id="" />
                                        {this.props.inputFieldInvalid === true && <div className="error field-error">Please enter location</div>}
                                    </div>
                                    <button type="submit" className="hvr-grow btn btn-lg btn-primary rounded-50 w-100 mb-8 ">
                                        Update
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default ForgotPasswordModal;
