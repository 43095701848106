import axios from "axios";
import * as actionType from "../../constants/orderActionType.js";

export const getOrders = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${actionType.environment_url}/api/orders-list`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    };
};
export const viewOrder = (form_data) => {
    return (dispatch) => {
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/orders-view`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    };
};
export const updateTrackingStatus = (form_data) => {
    return (dispatch) => {
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/update-tracking-status`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    };
};

export const cancelOrder =(form_data)=>{
    return(dispatch)=>{
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/cancel-order`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    }
}