import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import * as productActions from "../../actions/product";
import * as userActions from "../../actions/account";
import Loader from "../../components/Utility/Loader";
import { ColorRing } from "react-loader-spinner";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories:[],
            carouselResponsive: {
                superLargeDesktop: {
                    items: 5,
                    slidesToSlide: 3,
                    breakpoint: { max: 4000, min: 3000 },
                },
                desktop: {
                    items: 4,
                    slidesToSlide: 4,
                    breakpoint: { max: 3000, min: 1024 },
                },
                tablet: {
                    items: 2,
                    slidesToSlide: 2,
                    breakpoint: { max: 1024, min: 464 },
                },
                mobile: {
                    items: 1,
                    slidesToSlide: 1,
                    breakpoint: { max: 464, min: 0 },
                },
            },
            search_loader: false,
            website: null,
            sliderDiv:{
                height:'200px',
                width:'200px',
            },
            siderDivImg:{
                height:'100%',
                width:'100%',
                objectFit:'cover'
            },
        };
    }
    async componentDidMount() {
        this.setState({website: "http://www.taobao.com/"}) 

        this.props.getCategoriesList().then((response)=>{
            if(response?.data?.length> 0){
                response.data.shift()
                let data = response.data.filter((item,index)=>{
                    if(item.IconImageUrl){
                        return item
                    }
                })
                this.setState({
                    categories: data
                })
            }
        })



    }
    SearchHandler = (value) => {
        console.log(value,"value")
        const  search_input  = {
            search_input: value,
            type: "categories"
        };
        // const website = "http://www.taobao.com/";
        if (
            this.props?.page_type &&
            (this.props.page_type === "product_list" ||
                this.props.page_type === "product_details")
        ) {
            this.props.propsGetProduct(search_input);
        } else {
            this.setState({ search_loader: true });
            if (search_input) {
                console.log(search_input)
                this.props
                .getProduct(search_input, this.state.website)
                    .then((response) => {
                        console.log(
                            response,
                            "++++++++++++++++response login handler++++++++++++++++++++++++++++"
                        );
                        this.setState({ search_loader: false });
                        if (response.success === true) {
                            console.log(response.data, "response.data ")
                            if (response.data?.item) {
                                this.setState({
                                  is_redirect: true,
                                  redirect_url: `/product-detail/search?${search_input.search_input}`,
                                  redirect_state: {
                                    search_input: search_input.search_input,
                                    product_item: response.data?.item,
                                  },
                                });
                              } else if (response?.categories) {
                                if (response.data.length > 0) {
                                  console.log(response.data, "response.data inside");
                                  this.setState({
                                    is_redirect: true,
                                    redirect_url: `/product-categories/categories?${search_input.search_input}`,
                                    redirect_state: {
                                      categories: search_input.search_input,
                                      product_categories: response.data,
                                      currencyRate: response.currencyRate,
                                    },
                                  });
                                } else {
                                  toast.error("No Result Found", {
                                    position: toast.POSITION.TOP_RIGHT,
                                  });
                                }
                              } else {
                                if (response.data.length > 0) {
                                  this.setState({
                                    is_redirect: true,
                                    redirect_url: `/product-list/search?${search_input.search_input}`,
                                    redirect_state: {
                                      search_input: search_input,
                                      product_details: response.data,
                                      currencyRate: response.currencyRate,
                                    },
                                  });
                                } else {
                                  toast.error("No Result Found", {
                                    position: toast.POSITION.TOP_RIGHT,
                                  });
                                }
                              }
                              
                        } else {
                            toast.error(response?.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error("Error occurred during search:", error);
                        this.setState({ search_loader: false });
                        toast.error("An error occurred during search", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            } else {
                this.setState({ search_loader: false });
                toast.error("Please add some text or link to search items", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    handlerDropDownChange = (value) => {
        this.setState({website: value}) 
    }


    render() {
        const { carouselResponsive,categories,sliderDiv,sliderDivImg } = this.state;

        return (
            <React.Fragment>
            {this.state.is_redirect ? <Navigate to={this.state.redirect_url} state={this.state.redirect_state} /> : null}
            {this.state.search_loader ? <Loader /> : null}
                <div className="">
                    <LoginHeader handleDropdownChange={this.handlerDropDownChange} />
                    <div className="category-slider">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="heading">
                                    <h1>Categories</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="logo-slider">
                                    <div className="home_carousel">
                                        <Carousel
                                            swipeable={false}
                                            draggable={false}
                                            showDots={true}
                                            responsive={carouselResponsive}
                                            ssr={true}
                                            infinite={false}
                                            autoPlay={true}
                                            autoPlaySpeed={1000}
                                            keyBoardControl={true}
                                            customTransition="all .5"
                                            transitionDuration={500}
                                            containerClass="carousel-container"
                                            removeArrowOnDeviceType={["tablet", "mobile"]}
                                            dotListClass="custom-dot-list-style"
                                            itemClass="carousel-item-padding-40-px"
                                        >
                                            {categories.map((item,index)=>{
                                                {/* console.log(item) */}
                                               return( 
                                                <div className="item" onClick={() => this.SearchHandler(item.Id)}>
                                                {/* this.SearchHandler("Home Theater") */}
                                                <div className="slider">
                                                <div style={sliderDiv}>

                                                    <img
                                                        className="img1"
                                                        src={item.IconImageUrl}
                                                        alt="slide"
                                                        style={sliderDivImg}
                                                    />
                                                </div>
                                                    <p>{item.Name}</p>
                                                </div>
                                            </div>)
                                            })} 
                                            {/* <div className="item" onClick={() => this.SearchHandler("Home Theater")}>
                                                <div className="slider">
                                                    <img
                                                        className="img1"
                                                        src="assets/images/slide1.png"
                                                        alt="slide"
                                                    />
                                                    <p>Home Theater</p>
                                                </div>
                                            </div>
                                            <div className="item" onClick={() => this.SearchHandler("Accessories")}>
                                                <div className="slider">
                                                    <img
                                                        className="img2"
                                                        src="assets/images/slide2.png"
                                                        alt="slide"
                                                    />
                                                    <p>Accessories</p>
                                                </div>
                                            </div>
                                            <div className="item" onClick={() => this.SearchHandler("Appliances")}>
                                                <div className="slider">
                                                    <img
                                                        className="img3"
                                                        src="assets/images/slide3.png"
                                                        alt="slide"
                                                    />
                                                    <p>Appliances</p>
                                                </div>
                                            </div>
                                            <div className="item" onClick={() => this.SearchHandler("Bags & Accessories")}>
                                                <div className="slider">
                                                    <img
                                                        className="img4"
                                                        src="assets/images/slide4.png"
                                                        alt="slide"
                                                    />
                                                    <p>Bags & Accessories</p>
                                                </div>
                                            </div>
                                            <div className="item" onClick={() => this.SearchHandler("Electronics")}>
                                                <div className="slider">
                                                    <img
                                                        className="img5"
                                                        src="assets/images/slide5.png"
                                                        alt="slide"
                                                    />
                                                    <p>Electronics</p>
                                                </div>
                                            </div>
                                            <div className="item" onClick={() => this.SearchHandler("Entertainment")}>
                                                <div className="slider">
                                                    <img
                                                        className="img6"
                                                        src="assets/images/slide6.png"
                                                        alt="slide"
                                                    />
                                                    <p>Entertainment</p>
                                                </div>
                                            </div> */}
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginFooter />
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        productData: state.Product.productData,
    };
}
const mapDispatchToProps = {
    onCart: userActions.GetCart,
    getProduct: productActions.Getproduct,
    getCategoriesList: productActions.getCategoriesList,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Home);

