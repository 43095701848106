import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reset_form: {
        password: {
          type: "password",
          label: "Password",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Enter your password",
          validations: { required: true },
        },
        confirm_password: {
          type: "password",
          label: "confirm_password",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "Confirm password",
          validations: { required: true },
        },
      },
      loader: false,
      password: null,
      confirmPassword: null,
    };
  }

  componentDidMount() {
    console.log("props-------", this.props);
  }

  inputChangeHandler(event, identifier) {
    const update_reset_form = { ...this.state.reset_form };
    const form_element = { ...update_reset_form[identifier] };
    form_element.value = event.target.value;

    // Check Validation
    let validate_element = Validations(event, form_element);
    form_element.valid = validate_element.valid;
    form_element.error_msg = validate_element.error_msg;

    update_reset_form[identifier] = form_element;

    this.setState({
      reset_form: update_reset_form,
      password: event.target.value,
    });
  }

  confirmPassword(event, identifier) {
    const update_reset_form = { ...this.state.reset_form };
    const form_element = { ...update_reset_form[identifier] };
    form_element.value = event.target.value;

    update_reset_form[identifier] = form_element;
    this.setState({ reset_form: update_reset_form });
    const { password } = this.state;
    if (event.target.value !== password) {
      this.setState({
        confirmPassword: "Passwords don't match",
      });
    } else {
      this.setState({
        confirmPassword: null,
      });
    }
  }

  SubmitHandler = (event) => {
    event.preventDefault();

    let is_form_valid = true;
    const update_reset_form = this.state.reset_form;
    if (update_reset_form.confirm_password.value === "" && update_reset_form.password.value) {
      this.setState({
        confirmPassword: "Confirm Passwords required",
      });
      console.log("empty")
    }
    for (let key in update_reset_form) {
      let form_element = update_reset_form[key];
      let validate_element = Validations(event, form_element);

      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      is_form_valid = this.state.confirmPassword ? false : is_form_valid;
      form_element.onBlur_out = true;
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;

      update_reset_form[key] = form_element;
    }
    this.setState({ reset_form: update_reset_form });

    if (is_form_valid) {
      const form_data = new FormData();
      for (let key in this.state.reset_form) {
        form_data.append(key, this.state.reset_form[key].value);
      }
      const queryParams = window.location.href;
      var rest = queryParams.split("/").pop();
      form_data.append("email", rest);

      this.props.changePassword(form_data).then((response) => {
        if (response.success === true) {
          console.log(response, "response");
          toast.success("Password update successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            this.setState({
              isAuthenticated: true,
              redirect_url: "/",
            });
          }, 1000);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  render() {
    const { reset_form, confirmPassword } = this.state;

    console.log(this.props, "this props");
    return (
      <React.Fragment>
        <ToastContainer />
        {this.state.loader ? <Loader /> : null}
        {this.props.loader ? <Loader /> : null}
        {this.state.isAuthenticated ? (
          <Navigate to={this.state.redirect_url} />
        ) : null}

        <div className="login-sec">
          <div className="conatiner-fluid">
            <div className="row">
              <div className="login-form">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="field-sec">
                        <form onSubmit={this.SubmitHandler}>
                          <h3 className="sing_in">Reset Password</h3>
                          <div className="form-group">
                            <input
                              type={reset_form.password.type}
                              className="form-control form-control-lg form-control-solid"
                              placeholder={reset_form.password.placeholder}
                              value={
                                reset_form.password.value
                                  ? reset_form.password.value
                                  : ""
                              }
                              onChange={(event) =>
                                this.inputChangeHandler(event, "password")
                              }
                              onBlur={(event) => {
                                reset_form.password.onBlur_out = true;
                                this.setState({
                                  reset_form: reset_form,
                                });
                              }}
                            />
                            {!reset_form.password.valid &&
                              reset_form.password.onBlur_out && (
                                <div className="error field-error">
                                  {reset_form.password.error_msg}
                                </div>
                              )}
                          </div>
                          <div className="form-group">
                            <input
                              type={reset_form.confirm_password.type}
                              className="form-control form-control-lg form-control-solid"
                              placeholder={
                                reset_form.confirm_password.placeholder
                              }
                              value={
                                reset_form.confirm_password.value
                                  ? reset_form.confirm_password.value
                                  : ""
                              }
                              onChange={(event) =>
                                this.confirmPassword(event, "confirm_password")
                              }
                              onBlur={(event) => {
                                reset_form.confirm_password.onBlur_out = true;
                                this.setState({
                                  reset_form: reset_form,
                                });
                              }}
                            />
                            {
                              confirmPassword && (
                                <div className="error field-error">
                                  {confirmPassword}
                                </div>
                              )}
                            {/* {!reset_form.confirm_password.valid &&
                              reset_form.confirm_password.onBlur_out && (
                                <div className="error field-error">
                                {confirmPassword}
                                  {reset_form.confirm_password.error_msg}
                                </div>
                              )} */}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                          >
                            Confirm
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="img-sec">
                        <img src="/assets/images/sign-in.png" alt="login" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error,
  };
}
const mapDispatchToProps = {
  changePassword: userActions.ResetPassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
