import React from "react";

import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";

class HowToBuy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active1: false,
            active2: false,
            active3: false,
            active4: false,
            active5: false,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    setActive1 = () => {
        if (this.state.active1) {
            this.setState({
                active1: false,
            });
        } else {
            this.setState({
                active1: true,
            });
        }
    }
    setActive2 = () => {
        if (this.state.active2) {
            this.setState({
                active2: false,
            });
        } else {
            this.setState({
                active2: true,
            });
        }
    }
    setActive3 = () => {
        if (this.state.active3) {
            this.setState({
                active3: false,
            });
        } else {
            this.setState({
                active3: true,
            });
        }
    }
    setActive4 = () => {
        if (this.state.active4) {
            this.setState({
                active4: false,
            });
        } else {
            this.setState({
                active4: true,
            });
        }
    }
    setActive5 = () => {
        if (this.state.active5) {
            this.setState({
                active5: false,
            });
        } else {
            this.setState({
                active5: true,
            });
        }
    }
    render() {
        const { setActive1, setActive2, setActive3, setActive4, setActive5 } = this;
        const { active1, active2, active3, active4, active5 } = this.state;
        return (
            <React.Fragment>
                <div className="">
                    <LoginHeader />
                    <div className="top-content faq-bg">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="main-heading">
                                        <h1>faq</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-sec">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Frequently asked questions</h2>

                                    <dl className="faq">
                                        <dt>
                                            <button onClick={() => setActive1()}>{active1 ? "-" : "+"} Here are many variations of passages of Lorem Ipsum available ?</button>
                                        </dt>
                                        <dd>
                                            {active1 && (
                                                <p id="faq1_desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                                    galley of type and scrambled.
                                                </p>
                                            )}
                                        </dd>
                                        <dt>
                                            <button onClick={() => setActive2()}>{active2 ? "-" : "+"} If you are going to use a passage of Lorem Ipsum ?</button>
                                        </dt>
                                        <dd>
                                            {active2 && (
                                                <p id="faq1_desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                                    galley of type and scrambled.
                                                </p>
                                            )}
                                        </dd>
                                        <dt>
                                            <button onClick={() => setActive3()}>{active3 ? "-" : "+"} The standard chunk of Lorem Ipsum used since the 1500s ?</button>
                                        </dt>
                                        <dd>
                                            {active3 && (
                                                <p id="faq1_desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                                    galley of type and scrambled.
                                                </p>
                                            )}
                                        </dd>
                                        <dt>
                                            <button onClick={() => setActive4()}>{active4 ? "-" : "+"} Here are many variations of passages of Lorem Ipsum available ?</button>
                                        </dt>
                                        <dd>
                                            {active4 && (
                                                <p id="faq1_desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                                    galley of type and scrambled.
                                                </p>
                                            )}
                                        </dd>
                                        <dt>
                                            <button onClick={() => setActive5()}>{active5 ? "-" : "+"} The standard chunk of Lorem Ipsum used since the 1500s ?</button>
                                        </dt>
                                        <dd>
                                            {active5 && (
                                                <p id="faq1_desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                                    galley of type and scrambled.
                                                </p>
                                            )}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginFooter />
                </div>
            </React.Fragment>
        );
    }
}

export default HowToBuy;
