import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

/*** AddCart Action ***/
export const AddCartStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.AddCart_START,
    };
};

export const GetCartStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.GetCart_START,
    };
}

export const AddCartSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: Master_actionType.AddCart_SUCCESS,
    };
};

export const GetCartSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: Master_actionType.GetCart_SUCCESS,
    };
};

export const AddCartFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.AddCart_FAIL,
    };
};

export const AddAddressStart = () => {
    return {
        error: null,
        loader: true,
        type: actionType.Address_START,
    };
};

export const AddAddressSuccess = () => {
    return {
        token: false,
        loader: false,
        type: actionType.Address_SUCCESS,
    };
};

export const AddAddressFail = () => {
    return {
        error: null,
        loader: false,
        type: actionType.Address_FAIL,
    };
};

export const GetCartFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.GetCart_FAIL,
    };
};

export const AddWishlistStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.AddWishlist_START,
    };
};

export const AddWishlistSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: Master_actionType.Wishlist_SUCCESS,
    };
};

export const AddWishlistFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.Wishlist_FAIL,
    };
};

export const GetWishlistStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.GetWishlist_START,
    };
};

export const GetWishlistSuccess = () => {
    return {
        error: null,
        loader: false,
        type: Master_actionType.GetWishlist_SUCCESS,
    };
};

export const GetWishlistFail = () => {
    return {
        error: null,
        loader: false,
        type: Master_actionType.GetWishlist_FAIL,
    };
};

/*** Order Action ***/
export const OrderStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.Checkout_START,
    };
};

export const OrderSuccess = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.Checkout_SUCCESS,
    };
};

export const OrderFail = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.Checkout_FAIL,
    };
};

export const QtyUpStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.QtyUp_START,
    };
}

export const QtyUpSuccess = (response) => {
    return {
        token: false,
        loader: false,
        data: response,
        type: Master_actionType.QtyUp_SUCCESS,
    };
};

export const QtyUpFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.QtyUp_FAIL,
    };
};

// Currency Rate
export const CurrencyRateStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.CURRENCY_RATE_START,
    };
}

export const CurrencyRateSuccess = (response) => {
    return {
        token: false,
        loader: false,
        data: response,
        type: Master_actionType.CURRENCY_RATE_SUCCESS,
    };
};

export const CurrencyRateFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.CURRENCY_RATE_FAIL,
    };
};

export const GetCurrencyRateStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.GET_CURRENCY_RATE_START,
    };
}

export const GetCurrencyRateSuccess = (response) => {
    return {
        token: false,
        loader: false,
        data: response,
        type: Master_actionType.GET_CURRENCY_RATE_SUCCESS,
    };
};

export const GetCurrencyRateFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.GET_CURRENCY_RATE_FAIL,
    };
};

export const AddAddress = (form_data) => {
    return (dispatch) => {
        dispatch(AddAddressStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/address`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(AddAddressSuccess(response?.message));
                return response.message;
            })
            .catch(function (error) {
                dispatch(AddAddressFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const GetAddress = () => {
    return (dispatch) => {
        // dispatch(GetCartStart());
        return axios({
            method: "get",
            url: `${actionType.environment_url}/api/get-address`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                // dispatch(GetCartSuccess("Get Cart successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                // dispatch(GetCartFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 

export const AddCart = (form_data) => {
    return (dispatch) => {
        dispatch(AddCartStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/AddCart`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(AddCartSuccess("User login successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddCartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const GetCart = () => {
    return (dispatch) => {
        dispatch(GetCartStart());
        return axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetCart`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(GetCartSuccess("Get Cart successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCartFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 

export const CartQtyUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(QtyUpStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/QtyUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(QtyUpSuccess(response.data.data));
                console.log(response.data.data , "QtyUpdate Response")
                return response.data.data;
            })
            .catch(function (error) {
                dispatch(QtyUpFail("Something went wrong, Please try again."));
                return error;
            });
    };
}

export const RemoveProduct = (cartId) => {
    return (dispatch) => {
        // dispatch(QtyUpStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/remove-product`,
            params: { cartId: cartId },
        })
            .then(function (response) {
                // dispatch(QtyUpSuccess(response.data.data));
                console.log(response.data , "Remove Product")
                return response.data;
            })
            .catch(function (error) {
                dispatch(QtyUpFail("Something went wrong, Please try again. RemoveProduct"));
                return error;
            });
    };
} 

export const OrderCreate = (form_data) => {
    return (dispatch) => {
        dispatch(OrderStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/Order`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(OrderSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(OrderFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 

export const AddWishlist = (form_data) => {
    return (dispatch) => {
        dispatch(AddWishlistStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/AddWishlist`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(AddWishlistSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddWishlistFail("Something went wrong, Please try again."));
                return error;
            });
    };
}

export const GetWishlist = () => {
    return (dispatch) => {
        dispatch(GetWishlistStart());
        return axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetWishlist`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(GetWishlistSuccess("Get Cart successfully !!!"));
                return response.data.data;
            })
            .catch(function (error) {
                dispatch(GetWishlistFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 

export const WishlistQtyUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(QtyUpStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/wishlist-qty-update`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(QtyUpSuccess(response.data.data));
                console.log(response.data.data , "QtyUpdate Response")
                return response.data.data;
            })
            .catch(function (error) {
                dispatch(QtyUpFail("Something went wrong, Please try again."));
                return error;
            });
    };
}

export const RemoveFromWishlist = (cartId) => {
    return (dispatch) => {
        // dispatch(QtyUpStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/remove-from-wishlist`,
            params: { cartId: cartId },
        })
            .then(function (response) {
                // dispatch(QtyUpSuccess(response.data.data));
                console.log(response.data , "Remove Product")
                return response.data;
            })
            .catch(function (error) {
                dispatch(QtyUpFail("Something went wrong, Please try again. RemoveProduct"));
                return error;
            });
    };
}

// Currency Rate And Update
export const SetCurrencyRate = (form_data) => {
    return (dispatch) => {
        dispatch(CurrencyRateStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/SetCurrencyRate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CurrencyRateSuccess("Currency Rate successfully Save !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CurrencyRateFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 

export const GetCurrencyRate = () => {
    return (dispatch) => {
        dispatch(GetCurrencyRateStart());
        return axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetCurrencyRate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(GetCurrencyRateSuccess("Currency Rate Successfully Get !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCurrencyRateFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 

// get shipping const
export const ShippingCost = (form_data) => {
    return (dispatch) => {
        dispatch(OrderStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/shipping-cost`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(OrderSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(OrderFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 