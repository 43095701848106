import * as Master_actionType from "../constants/MasteractionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case Master_actionType.AddCart_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.AddCart_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.AddCart_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };
        case Master_actionType.GetCart_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.GetCart_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
            };
        case Master_actionType.GetCart_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };
        case Master_actionType.QtyUp_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.QtyUp_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                data: action.data
            };
        case Master_actionType.QtyUp_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        default:
            return state;
    }
};

export default reducer;