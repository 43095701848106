import React from "react";

import { Blocks } from  'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loader = (props) => {
    return (
        <div className="loader">
			<Blocks
			  width="150"
			  height="150"
			  visible={true}
			  color="#4fa94d"
			  ariaLabel="blocks-loading"
			  wrapperClass="blocks-wrapper"
			/>
        </div>
    );
};

export default Loader;