import React from "react";

import { useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ProductList from "../../components/Application/Products/ProductList";
import ProductDetail from "../../components/Application/Products/ProductDetail";
import ProductCategories from "../../components/Application/Products/ProductCategories";

const Account = (props) => {
	
    const Renderview = () => {
        const location = useLocation();
        
        if (location.pathname.match(/\/(product-list.+)/)) {
            // console.log(location, "/:search");
            return <ProductList location={location} />;
        }

        if (location.pathname.match(/\/(product-detail.+)/)) {
            // console.log(location, "/:search");
            return <ProductDetail location={location} />;
        }

        if (location.pathname.match(/\/(product-categories.+)/)) {
            // console.log(location, "/:search");
            return <ProductCategories location={location} />;
        }
    };

    return (
        <React.Fragment>            
            {Renderview()}
        </React.Fragment>
    );
};
export default Account;