import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';


import * as userActions from "../../../actions/account";
import * as checkoutActions from "../../../actions/checkout";
import Loader from "../../../components/Utility/Loader";
import LoginHeader from "../../../components/LoginHeader";
import LoginFooter from "../../../components/LoginFooter";
import getSymbolFromCurrency from 'currency-symbol-map';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Wishlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            total: null,
            quantity: null,
            checkoutData: null,
            buttonDIsable: false,
            columns: [],
            CurrencySymbol: getSymbolFromCurrency('NGN'),
        }
        this.DecreaseItem = this.DecreaseItem.bind(this);
        this.IncrementItem = this.IncrementItem.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);

    }

    componentWillMount() {
        window.scrollTo(0, 0);
        this.props.onWishlist().then((response) => {
            console.log(response, "componentDidMount on cart page")
            this.setState({
                products: response,
            })
        })
        const columns = [
            {
                name: 'Image',
                cell: row =><a href={`${process.env.REACT_APP_URL}/product-detail/search?${row.product_external_url}`}> <img src={row.wishlist_avatar}  style={{ width: '150px' }} /></a>,
                width: "300px"
            },
            { 
                name: 'Title',
                cell: row =><a href={`${process.env.REACT_APP_URL}/product-detail/search?${row.product_external_url}`}><span>{row.wishlist_title}</span></a>,
                sortable: true,
                width: "450px",
                wrap: true
            },
            { 
                name: 'Price',
                cell: row => <div class="price">
                    <span>{this.state.CurrencySymbol}</span>
                    <span>{row.price}</span>
                    </div>,
                sortable: true,
                width: "150px"
            },
            { 
                name: 'Note requirement',
                cell: row =><span class="requirement">{row.note_requirement}</span>,
                width: "250px",
                wrap: true
            },
            {
                name: 'Operation',
                cell: row => <div class='actions'><i class="fa fa-trash" onClick={() => this.deleteProduct(row.id)} ></i>
                            <i class="fa fa-shopping-cart" onClick={() => this.AddToCart(row)} ></i></div>,
                width: "150px"
            },
          ];
        this.setState({
            columns: columns
        })
    }

    DecreaseItem = (quantity, cartItemId) => {
        let updateQty = quantity - 1;
        const form_data = new FormData();
        form_data.append("quantity", updateQty);
        form_data.append("cartItemId", cartItemId);
        this.props.onQty(form_data).then((response) => {
            this.componentWillMount()
        })
    };

    IncrementItem = (quantity, cartItemId) => {
        let updateQty = parseInt(quantity) + 1
        const form_data = new FormData();
        form_data.append("quantity", updateQty);
        form_data.append("cartItemId", cartItemId);
        this.props.onQty(form_data).then((response) => {
            this.componentWillMount()
        })
    };

    deleteProduct = (cartId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this product?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.onRemove(cartId).then((response) => {
                            if (response.success === true) {
                                this.componentWillMount();
                                toast.success(response.message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            } else {
                                toast.error(response.message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }
    

    AddToCart = (value) => {
        console.log(value, "value")

            const form_data = new FormData();
            let product_detail = {
                title: value.wishlist_title,
                external_id: value.product_external_id,
                external_url: value.product_external_url,
                price: value.price,
                product_primary_image: value.wishlist_avatar,
                product_images: null,
                quantity: value.quantity
            }
            form_data.append("product_detail", JSON.stringify(product_detail));
            form_data.append("product_requirements", value.note_requirement);
            this.props.onAddCart(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
        
    };
    render() {
        const { products, checkoutData, product_sku_requirements, columns } = this.state;
        console.log("render+++++++++++", products.length)
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                <div className="">
                    <LoginHeader />
                    <div className="checkout-template">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title">
                                        <h1 className="d-flex">Wishlist</h1>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="item-list">
                                        {products.length > 0 &&
                                            
                                        <DataTable
                                            pagination
                                            title="Wishlist"
                                            columns={columns}
                                            data={products}
                                        />
                                            
                                        }
                                        {products.length === 0 &&
                                            <div className="empty-cart">
                                            <span>Your Wishlist is Empty</span>
                                            </div>
                                            
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginFooter />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onAddCart: userActions.AddCart,
    onWishlist: userActions.GetWishlist,
    onQty: userActions.WishlistQtyUpdate,
    onRemove: userActions.RemoveFromWishlist,
};
export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
