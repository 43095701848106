import { Navigate } from "react-router-dom";
import * as actions from "./actions";

const ProtectedRoute = ({ isLoggedIn, children }) => {
    if (actions.isAuthorize()) {
        return children;
    } else {
        return <Navigate to="/login" />;
    }
};
export default ProtectedRoute;
