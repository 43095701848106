import React from "react";
import { connect } from "react-redux";
import "../../../assets/css/superAdmin.css";
import { CSmartTable, CAvatar, CBadge, CButton, CCollapse, CCardBody, CListGroup } from '@coreui/react-pro' // Assuming you've installed the CoreUI package
import defaultImage from "../../../assets/static images/Default-welcomer.png";
import { Link } from "react-router-dom";
import * as userActions from "../../../actions/superAdmin/orders";
import Moment from 'react-moment';
class OrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersData: []
        }
    }
    componentDidMount() {
        this.props.getOrders().then((response) => {
            if (response.success === true) {
                // console.log(object)
                this.setState({
                    ordersData: response.response
                })
            }
        })
    }

    render() {
        const details = [];
        const columns = [
            {
                label: '#',
                key: 'index',
                render: (text, record, index) => index + 1,
                filter: false,
                sorter: false,
            },
            {
                key: 'product_avatar',
                label: 'Image',
                filter: false,
                sorter: false,
            },
            {
                key: 'product_name',
            },
            {
                key: 'totalprice',
                label:'price'
            },
            {
                key: "created_at",
                label: 'Purchased date',
            },
            {
                key: 'status',
            },
            {
                key: 'show_details',
                label: 'Action',
                filter: false,
                sorter: false,
            },
        ]
        const { ordersData } = this.state;
        const getBadge = (status) => {
            switch (status) {
                case 'success':
                // case 'delivered':
                    return 'success'
                case 'Inactive':
                    return 'secondary'
                case 'shipped':
                    return 'warning'
                case 'Banned':
                    return 'danger'
                default:
                    return 'primary'
            }
        }
        return (
            <>
                <React.Fragment>
                    {/* <ToastContainer />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null} */}

                    <section className="home-section">
                        <nav>
                            <div className="sidebar-button">
                                <i className='bx bx-menu sidebarBtn'></i>
                                <span className="dashboard">Orders</span>
                            </div>
                        </nav>

                        <div className="home-content">
                            <div className="sales-boxes">
                                <div className="recent-sales box">
                                    <div className="title ">Orders</div>


                                    <CSmartTable
                                        activePage={1}
                                        cleaner
                                        clickableRows
                                        columns={columns}
                                        columnFilter
                                        columnSorter
                                        footer
                                        items={ordersData}
                                        itemsPerPageSelect
                                        itemsPerPage={5}
                                        pagination
                                        onFilteredItemsChange={(items) => {
                                            // console.log(items)
                                        }}
                                        onSelectedItemsChange={(items) => {
                                            // console.log(items)
                                        }}

                                        scopedColumns={{
                                            index: (item, index) => {
                                                console.log(item)
                                                return (
                                                    <td>{index + 1}</td>
                                                )
                                            },
                                            product_avatar: (item) => (
                                                <td>
                                                    <CAvatar src={item.product_avatar ? item.product_avatar : defaultImage} />
                                                </td>
                                            ),
                                            totalprice: (item) => (
                                                <td>
                                                    ${item.totalprice}
                                                </td>
                                            ), 
                                            product_name: (item) => (
                                                <td>
                                                    {item.order_items[0].product_name}
                                                </td>
                                            ),
                                            created_at: (item) => {
                                                return (<td>
                                                    <Moment format="DD/MM/YYYY">{item.created_at}</Moment>
                                                </td>)
                                            },
                                            status: (item) => (
                                                <td>
                                                    <CBadge color={getBadge(item.status)}>{item.status}</CBadge>
                                                </td>
                                            ),
                                            show_details: (item) => {
                                                return (
                                                    <td className="py-2">
                                                        <div className="d-flex">
                                                            <Link
                                                                to={`/super-admin-view-order/${btoa(item.id)}`}
                                                                className="btn btn-outline-primary btn-sm square"
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                            >
                                                                View
                                                            </Link>
                                                        </div>
                                                    </td>
                                                )
                                            },

                                            details: (item) => {
                                                return (
                                                    <CCollapse visible={details.includes(item.id)}>

                                                        <CCardBody className="p-3">
                                                            <h4>{item.name}</h4>
                                                            <p className="text-muted">User since: {item.registered}</p>
                                                            <CButton size="sm" color="info">
                                                                User Settings
                                                            </CButton>
                                                            <CButton size="sm" color="danger" className="ml-1">
                                                                Delete
                                                            </CButton>
                                                        </CCardBody>
                                                    </CCollapse>
                                                )
                                            },
                                        }}
                                        selectable
                                        sorterValue={{ column: 'status', state: 'asc' }}
                                        tableFilter
                                        tableProps={{
                                            className: 'add-this-class',
                                            responsive: true,
                                            striped: true,
                                            hover: true,
                                        }}
                                        tableBodyProps={{
                                            className: 'align-middle'
                                        }}
                                    />

                                </div>

                            </div>
                        </div>
                    </section>

                </React.Fragment>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    getOrders: userActions.getOrders,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OrderList);