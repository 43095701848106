import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import * as userActions from "../../actions/index";

class logout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        this.setState({ loader: true });
        this.props.onLogout().then((response) => {
            localStorage.removeItem("shiporfly_authuser");
            localStorage.removeItem("shiporfly_authtoken");
            window.location = "/";
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to="/signin" /> : null}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => userActions.Logout(),
    };
};
export default connect(null, mapDispatchToProps)(logout);
