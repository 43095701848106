const Validation = (event, form_element) => {
    let value = form_element.value;
    let rule = form_element.validations;

    value = typeof value === "object" ? JSON.stringify(value) : value;
    value = typeof value === "boolean" ? JSON.stringify(value) : value;

    if (rule?.required) {
        if (value == null || (typeof value === "string" && value.trim() === "") || (typeof value === "number" && value === "")) {
            return {
                valid: false,
                error_msg: "This field is required!",
            };
        }
    }

    // If filed is email
    if (rule?.email) {
        let email_regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        // let email_regexp = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        if (email_regexp.test(value) === false) {
            return {
                valid: false,
                error_msg: "Email format is incorrect.",
            };
        }
    }

    // If filed is zip
    if (rule?.zip) {
        var validZip = /^\d{4,6}$/g;
        if (validZip.test(value) === false) {
            return {
                valid: false,
                error_msg: "Invalid zip code.",
            };
        }
    }

    // Validate min length
    if (rule?.min_length) {
        if (value.length < rule.min_length) {
            return {
                valid: false,
                error_msg: "Please enter the correct format",
            };
        }
    }

    // Validate Max length
    if (rule?.max_length) {
        if (value.length > rule.max_length) {
            return {
                valid: false,
                error_msg: "Please enter the correct format",
            };
        }
    }

    if (!rule?.required) {
        return {
            valid: true,
            error_msg: "",
        };
    }

    return {
        valid: true,
        error_msg: "",
    };
};

export default Validation;
