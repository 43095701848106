import jwt_decode from 'jwt-decode';

export const isSuperAdminAuthorize = () => {
  const token = localStorage.getItem('shiporfly_super_admin_authtoken');
  if (!token) {
    return false;
  }
  try {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
  // const shiporfly_authuser = localStorage.getItem("shiporfly_authuser");
  // const shiporfly_authtoken = localStorage.getItem("shiporfly_authtoken");
  // let decoded = jwt.verify(token, process.env.shiporfly_authtoken);
  // return shiporfly_authuser && shiporfly_authtoken ? true : false;
};