import { Navigate } from "react-router-dom";
import * as actions from "./actions";

const SuperAdminProtectRoute = ({ isLoggedIn, children }) => {
    if (!actions.isSuperAdminAuthorize()) {
        return <Navigate to="/super-admin-login" />;
    } else {
        return children;
    }
};
export default SuperAdminProtectRoute;