import * as orderAction from "../constants/orderActionType";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case orderAction.GET_COMPLETE_ORDERS_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case orderAction.GET_COMPLETE_ORDERS_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                productData: action.productData,
                token: action.token,
            };
        case orderAction.GET_COMPLETE_ORDERS_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        default:
            return state;
    }
};

export default reducer;
