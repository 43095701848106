import React from "react";
import { Link } from "react-router-dom";

import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";

class HowToBuy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        
        window.scrollTo(0, 0);
    }
    render() {
        
        return (
            <>
                <React.Fragment>
                    <div className="">
                        <LoginHeader />
                        <div className="top-content about-bg">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-heading">
                                            <h1>About Us</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="about-sec">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image-sec">
                                            <img src="assets/images/about-img.png" alt="about" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-12">
                                        <div className="about-text">
                                            <h2>A Few Words about us</h2>
                                            <span>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                                                of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                            </span>

                                            <p>
                                                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions
                                                of Lorem Ipsum.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="map-sec">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <h3>Our Locations</h3>
                                        <div className="our-location-1 margin">
                                            <p>Office Address 1</p>
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-map-marker"></i>3403 Dawson Drive, Little Rock AR,Arkansas - 72002
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-phone"></i>501-847-2649, 501-779-2598
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="our-location-1">
                                            <p>Office Address 2</p>
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-map-marker"></i>3403 Dawson Drive, Little Rock AR,Arkansas - 72002
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-phone"></i>501-847-2649, 501-779-2598
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="map">
                                            <iframe
                                                title="google map"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.1444918202214!2d-92.57692378440545!3d34.72675208932955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87cd5ba1eaca31a7%3A0xab8450312e288bb6!2sDawson%20Springs%20Dr%2C%20Alexander%2C%20AR%2072002%2C%20USA!5e0!3m2!1sen!2sin!4v1634368034305!5m2!1sen!2sin"
                                                width="100%"
                                                height="423"
                                                style={{ border: "0" }}
                                                allowfullscreen=""
                                                loading="lazy"
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </React.Fragment>
            </>
        );
    }
}

export default HowToBuy;