import React, { Children, Component } from 'react'
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import Loader from "../../../components/Utility/Loader.js";
import defaultImage from "../../../assets/static images/Default-welcomer.png";
import Validations from "../../../components/Utility/Validations";
import * as action from "../../../actions/index.js";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class ViewComplaints extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerCard: {
                padding: '0px 22px',
                margin: '0 20px',
                'borderRadius': '12px',
                'boxShadow': '0 5px 10px rgba(0, 0, 0, 0.1)'
            },

            cardStyle: {
                width: "75%",
                height: "100%",
                'borderRadius': "20px"
            },
            isloading: true,
            divProfileImage: {
                height: "90px",
                width: "90px"
            },
            profileImage: {
                'borderRadius': '50%'
            },
            verifiedStyle: {
                'color': '#fff',
                'backgroundColor': '#0a2558',
                'borderRadius': '50%',
            },
            card: {
                height: '290px'
            },
            textArea: {
                minHeight: 'calc(5.5em + .75rem + 40px)'
            },
            complaint_form: {

                description: {
                    type: "text",
                    label: "Description",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },
            complaint: null,
            reply: null,
        }
    }

    componentDidMount() {

        const { location } = this.props;
        const id = location.pathname.replace("/super-admin-view-complaint/", '')
        this.props.viewComplaintAdmin(id).then((response) => {
            if (response.success === true) {
                const { complaint, reply } = response.data;
                this.setState({
                    complaint,
                    reply,
                    isloading: false,
                    complaint_form: {
                        description: {
                            type: "text",
                            label: "Description",
                            value: reply ? reply.description : '',
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "",
                            validations: { required: true },
                        },
                    }
                })
            } else {
                this.setState({
                    isloading: false
                })
                console.log("error")
            }
        });
    }
    inputChangeHandler = (event, identifier) => {
        const update_complaint_form = { ...this.state.complaint_form };
        const form_element = { ...update_complaint_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(event, form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_complaint_form[identifier] = form_element;

        this.setState({ complaint_form: update_complaint_form });

    }

    SubmitHandler = (event) => {
        const { location } = this.props;
        const id = location.pathname.replace("/super-admin-view-complaint/", '')
        event.preventDefault();
        this.setState({
            isloading: true
        });
        let is_form_valid = true;
        const update_complaint_form = this.state.complaint_form;
        for (let key in update_complaint_form) {
            let form_element = update_complaint_form[key];

            let validate_element = Validations(event, form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_complaint_form[key] = form_element;
        }
        this.setState({ complaint_form: update_complaint_form });
        if (is_form_valid) {
            const form_data = new FormData();
            form_data.append("description", update_complaint_form.description.value)
            form_data.append("complaint_id", id)
            if (this.state.reply) {
                form_data.append('id', this.state.reply.id)
            }
            this.props.replyComplaints(form_data).then((response) => {
                if (response.success === true) {
                    toast.success("Replied successfully", { position: toast.POSITION.TOP_RIGHT });
                    this.props.viewComplaintAdmin(id).then((response) => {
                        if (response.success === true) {
                            const { complaint, reply } = response.data;
                            this.setState({
                                complaint,
                                reply,
                                complaint_form: {
                                    description: {
                                        type: "text",
                                        label: "Description",
                                        value: reply ? reply.description : '',
                                        valid: true,
                                        error_msg: "",
                                        onBlur_out: false,
                                        placeholder: "",
                                        validations: { required: true },
                                    },
                                }
                            })
                            setTimeout(() => {
                                this.setState({
                                    isloading: false
                                });
                            }, 300)
                        } else {
                            this.setState({
                                isloading: false
                            })
                            console.log("error")
                        }
                    });

                } else {
                    setTimeout(() => {
                        this.setState({
                            isloading: false
                        });
                    }, 300)
                    toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
                    console.log("error")
                }
            })

        } else {
            setTimeout(() => {
                this.setState({
                    isloading: false
                });
            }, 300)
        }
    }
    handleClose(event, id) {
        event.preventDefault()
        const form_data = new FormData();
        form_data.append('id', id);
        this.setState({
            isloading: true
        });
        confirmAlert({
            title: "Are you sure?",
            message: `The complaint has been resolved!`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        this.props.complaintResoved(form_data).then((response) => {
                            if (response.success === true) {
                                toast.success("Complaint Resolved successfully", { position: toast.POSITION.TOP_RIGHT });
                                setTimeout(() => {
                                    this.setState({
                                        isloading: false
                                    });
                                    event.target.classList.remove('btn-danger');
                                    event.target.classList.add('btn-success');
                                    event.target.innerText = "Resolved"
                                }, 300)

                            } else {
                                setTimeout(() => {
                                    this.setState({
                                        isloading: false
                                    });
                                }, 300)
                                toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
                                console.log("error")
                            }
                        })
                    }
                },
                {
                    label: 'No',
                }
            ]
        })
    }
    render() {
        const { complaint, reply, isloading, card, complaint_form, textArea } = this.state;
        console.log(complaint)
        console.log(reply)
        return (
            <>
                <React.Fragment>
                    {isloading === true ? <Loader /> : ''}
                    <ToastContainer />
                    <section className="home-section">
                        <nav>
                            <div className="sidebar-button">
                                <i className='bx bx-menu sidebarBtn'></i>
                                <span className="dashboard">User</span>
                            </div>
                        </nav>

                        <div className="home-content">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card" style={this.state.headerCard}>
                                        <div className="card-header bg-white">
                                            <div className="d-flex align-items-center">
                                                <h5 className="card-title flex-grow-1 mb-0">User </h5>
                                                <div className="flex-shrink-0">
                                                    {complaint && complaint.status === "active" && reply ? <div onClick={(event) => this.handleClose(event, complaint.id)} className="btn btn-danger btn-sm">Close</div> : ''}
                                                    {complaint &&
                                                        complaint.status === "active" && reply === null ?
                                                        <div className="btn btn-secondary btn-sm">Pending</div> : ''
                                                    }
                                                    {complaint &&
                                                        complaint.status === "closed" ?
                                                        <div className="btn btn-success btn-sm">Resolved</div> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container my-2">
                                    <div className="row mx-3">
                                        <div className="col-md-6">
                                            <div className="card profile-sidebar" style={this.state.cardStyle}>


                                                <div className="profile-userpic m-auto" style={this.state.divProfileImage}>
                                                    <img className="card-img-top img-responsive mt-3" style={this.state.profileImage} alt="" src="/static/media/Default-welcomer.ca7543eb8c998f624dc7.png" height="100%" width="100%" />
                                                </div>


                                                <div className="profile-usertitle text-center mt-3">
                                                    <div className="profile-usertitle-name">
                                                        {complaint ? complaint.full_name : ''}<i
                                                            style={this.state.verifiedStyle}
                                                            className="fa fa-check"></i>
                                                    </div>
                                                </div>




                                                <div className="portlet light bordered">

                                                    <div>
                                                        <div className="margin-top-20 profile-desc-link">
                                                            <i className="bx bx-envelope"></i>
                                                            <a href={`mailto:${complaint ? complaint.email : ''}`} className='text-dark'>{complaint ? complaint.email : ''}</a>
                                                        </div>
                                                        <div className="margin-top-20 profile-desc-link">
                                                            <i className="bx bx-phone-call"></i>
                                                            <a href={`tel:${complaint ? complaint.phone_no : ''}`} className='text-dark'>{complaint ? complaint.phone_no : ''}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="container-fluid">
                                        <div className="row mt-3">
                                            <div className="col-md-6 mb-3 col-lg-6">
                                                <div className="card">
                                                    <h5 className='text-center mt-3 mb-3'>Complaint</h5>
                                                    <div className="container">
                                                        <form className='mb-4'>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="exampleInputEmail1">Subject</label>
                                                                <input className="form-control" type="text" placeholder="Readonly input here..." value={complaint ? complaint.subject : ''} readOnly />

                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="exampleInputPassword1">Description</label>
                                                                <textarea className="form-control" id="validationTextarea" placeholder="Required example textarea" readOnly rows="10" value={complaint ? complaint.description : ''}></textarea>

                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="card" style={card}>
                                                    <h5 className='text-center mt-3 mb-3'>Reply</h5>
                                                    <div className="container">
                                                        <form className='mb-4' onSubmit={this.SubmitHandler}>
                                                            <div className="form-group mb-3">
                                                                <label htmlFor="exampleInputPassword1">Description</label>
                                                                <textarea style={textArea} rows={5} name="description" className="form-control" placeholder=""
                                                                    value={complaint_form.description.value ? complaint_form.description.value : ''}
                                                                    onChange={(event) => this.inputChangeHandler(event, "description")}
                                                                    onBlur={(event) => {
                                                                        complaint_form.description.onBlur_out = true;
                                                                        this.setState({
                                                                            complaint_form: complaint_form,
                                                                        });
                                                                    }}></textarea>
                                                                {!complaint_form.description.valid && complaint_form.description.onBlur_out && <div className="error field-error">{complaint_form.description.error_msg}</div>}

                                                            </div>
                                                            <div className="form-group mt-2 text-end">
                                                                <button type='submit' className="btn btn-outline-primary btn-sm square me-3 rounded-circle h-auto w-auto" color="primary" variant="outline" shape="square" ><i className="fa fa-send"></i></button>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </React.Fragment >
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}


const mapDispatchToProps = {
    viewComplaintAdmin: action.viewComplaintAdmin,
    replyComplaints: action.replyComplaints,
    complaintResoved: action.complaintResoved,
};

export default connect(mapStateToProps, mapDispatchToProps, null)(ViewComplaints)
